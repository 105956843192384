import { useEffect, useState } from "react";
import { useAuth } from "../../../AuthProvider";
import Login from "../../Login";
import { useCheckout } from "../../Subscription/useCheckout";
import { useNavigate } from "react-router-dom";

export default function Pricing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonText, setButtonText] = useState("View additional plans");
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";

  const { setShowSuccessFirstSubs, loading } = useCheckout(gotoGV);

  function gotoGV(v: any) {
    if (v.name === "checkout.completed") {
      setShowSuccessFirstSubs(true);
    }
  }

  function goToDashboard() {
    navigate("/app/dashboard");
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const marginTop = {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  } as const;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const closeButtonStyle: React.CSSProperties = {
    position: "absolute",
    top: isMobileDevice() ? "125px" : "35px", // Adjust top position based on mobile or desktop
    right: isMobileDevice() ? "20px" : "45px", // Adjust right position based on mobile or desktop
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    fontSize: "28px",
    color: "#fff",
  };

  if (windowWidth < 1600 && !isMobileDevice()) {
    closeButtonStyle.right = "-170px";
  }

  if (!currentUser) {
    return (
      <div className="d-flex flex-column flex-grow-1" style={marginTop}>
        <div className="d-flex flex-column m-auto" style={marginTop}>
          <Login />
        </div>
      </div>
    );
  }

  const titleStyle =
    windowWidth < 1700
      ? { fontWeight: "bolder", marginBottom: "0.3rem" }
      : { fontWeight: "bolder", marginBottom: "1rem" };

  const subtitleStyle = {
    marginBottom: isMobileDevice() || windowWidth < 1700 ? "5rem" : "3rem",
  };

  const containerStyle = {
    backgroundColor: "#232627",
    height: "100%",
    overflow: isMobileDevice() ? "auto" : "initial",
  };
  const togglePricingTable = () => {
    setIsExpanded(!isExpanded);
    setButtonText(
      isExpanded ? "View additional plans" : "Back to previous plans"
    );
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`ps d-flex flex-column justify-content-start flex-grow-1 text-center ${
            windowWidth < 1700 ? "p-1" : "p-2"
          }`}
          style={containerStyle}
        >
          <button onClick={goToDashboard} style={closeButtonStyle}>
            &times;
          </button>
          <h3 className="text-center" style={titleStyle}>
            {title}
          </h3>
          <span
            className="text-muted"
            style={{ ...subtitleStyle, fontSize: "small" }}
          >
            {subtitle}
          </span>
          <div className="create-video-btn btn" onClick={togglePricingTable}>
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                alignContent: "center",
                marginRight: "5px",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={loading}
            >
              {buttonText}
            </button>
          </div>
          <div style={{ height: "150%" }}>
            {isExpanded ? (
              <stripe-pricing-table
                pricing-table-id="prctbl_1Q5YWoGkO49MG6xzx2A47qj5"
                publishable-key="pk_test_51Ns0HeGkO49MG6xzAwAWC0GDTbZpwz0BUwIYlThVemQV2vulSNS6Gg4QiJPdEXkXXFp0tk1GzKVFH4HvZmvHI3bD00fLaQKkOe"
                customer-email={ownerId}
              ></stripe-pricing-table>
            ) : (
              <stripe-pricing-table
                pricing-table-id="prctbl_1PxaiKGkO49MG6xzk3PCYnss"
                publishable-key="pk_test_51Ns0HeGkO49MG6xzAwAWC0GDTbZpwz0BUwIYlThVemQV2vulSNS6Gg4QiJPdEXkXXFp0tk1GzKVFH4HvZmvHI3bD00fLaQKkOe"
                customer-email={ownerId}
              ></stripe-pricing-table>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`ps d-flex flex-column justify-content-start flex-grow-1 text-center ${
            windowWidth < 1700 ? "p-1" : "p-2"
          }`}
          style={containerStyle}
        >
          <button onClick={goToDashboard} style={closeButtonStyle}>
            &times;
          </button>
          <h1 className="text-center" style={titleStyle}>
            {title}
          </h1>
          <h5 className="text-muted" style={subtitleStyle}>
            {subtitle}
          </h5>

          <div className="create-video-btn btn" onClick={togglePricingTable}>
            <button
              style={{
                border: "none",
                backgroundColor: "transparent",
                alignContent: "center",
                marginRight: "5px",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={loading}
            >
              {buttonText}
            </button>
          </div>

          <div style={{ height: "100%" }}>
            {isExpanded ? (
              <stripe-pricing-table
                pricing-table-id="prctbl_1Q5YWoGkO49MG6xzx2A47qj5"
                publishable-key="pk_test_51Ns0HeGkO49MG6xzAwAWC0GDTbZpwz0BUwIYlThVemQV2vulSNS6Gg4QiJPdEXkXXFp0tk1GzKVFH4HvZmvHI3bD00fLaQKkOe"
                customer-email={ownerId}
              ></stripe-pricing-table>
            ) : (
              <stripe-pricing-table
                pricing-table-id="prctbl_1PxaiKGkO49MG6xzk3PCYnss"
                publishable-key="pk_test_51Ns0HeGkO49MG6xzAwAWC0GDTbZpwz0BUwIYlThVemQV2vulSNS6Gg4QiJPdEXkXXFp0tk1GzKVFH4HvZmvHI3bD00fLaQKkOe"
                customer-email={ownerId}
              ></stripe-pricing-table>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const title = "Pricing";
const subtitle =
  "14 day free trial and non-expiring credits for infinite creativity";
