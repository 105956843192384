import { useEffect, useState } from "react";
import "./styles.css";
import { NextPrev } from "../AudioPage";
import {
  useAppSelector,
  useAudioTrim,
  useStartingFrame,
} from "../../store/hooks";
import { selectAudioAsset, setSelected } from "../../store/audioAssetReducer";
import { useDispatch } from "react-redux";
import AnimationModeSelector from "../GenerateVideo/ChooseStyle/AnimationModeSelector";
import MSetTransitionEffects from "../GenerateVideo/AudioPage/MSetTransitionsEffects";
import AspectRatioSelector from "../GenerateVideo/ChooseStyle/AspectRatioSelector";
import MSetCameraMovements from "../GenerateVideo/AudioPage/MSetCameraMovements";
import BoomerangSelector from "../GenerateVideo/ChooseStyle/BoomerangSelector";
import AudioReactivity from "../GenerateVideo/AudioPage/AudioReactivity";
import Name from "../GenerateVideo/ChooseStyle/Name";
import Navigator from "../GenerateVideo/AudioPage/SelectStartAndStop/Navigator";
import { AudioAsset } from "../../API";
import TrimPlayer from "../GenerateVideo/AudioPage/SelectStartAndStop/TrimPlayer";
import {
  selectTempAudio,
  setTempAudio,
} from "../../store/generateVideoReducer";
import { Modal, Spinner, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function AudioReactivityPage() {
  const { videoName } = useStartingFrame();
  const audio = useAppSelector(selectAudioAsset);
  const refAudio = useAppSelector(selectTempAudio);
  const n = useNavigate();
  const hasMusic =
    Object.keys(audio.selected)?.includes("size") ||
    refAudio.name?.includes("mp3");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!hasMusic) {
      setShowModal(true);
    }
  }, []);

  const handleConfirm = () => {
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    n(-1);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className="ps rounded m-2 d-flex flex-grow-1 flex-column align-items-center justify-content-center"
          style={{ backgroundColor: "#141718" }}
        >
          <div
            className="col-12 rounded d-flex flex-column p-1 gap-2"
            style={{ backgroundColor: "#343333" }}
          >
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
          </div>
          <div
            className={`d-flex flex-column flex-grow-1 ${
              window.innerWidth < 1600 ? "" : "gap-3"
            } overflow-hidden`}
          >
            {window.innerWidth < 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev next={"../choose-art-style"} />
              )}
            <Header />
            <Content />
          </div>
          {window.innerWidth > 1600 &&
            typeof window.innerWidth === "number" && (
              <div className="p-5 col-10">
                <NextPrev next={"../choose-art-style"} />
              </div>
            )}
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 flex-column align-items-center justify-content-center">
          <div
            className={`border-bottom col-12 d-flex p-3 ${
              window.innerWidth < 1600 ? "mb-3" : ""
            } gap-5`}
          >
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
            <BoomerangSelector />
          </div>
          <div
            className={`d-flex flex-column flex-grow-1 ${
              window.innerWidth < 1600 ? "" : "gap-3"
            } overflow-hidden`}
          >
            {window.innerWidth < 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev next={"../choose-art-style"} />
              )}
            <Header />
            <Content />
          </div>
          {window.innerWidth > 1600 &&
            typeof window.innerWidth === "number" && (
              <div className="p-5 col-10">
                <NextPrev next={"../choose-art-style"} />
              </div>
            )}
        </div>
      )}

      {showModal && (
        <ConfirmationModal
          show={showModal}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

//=======================================================================

function populateNav(selected: AudioAsset) {
  const channels: ChannelsType[] = [];
  if (selected.parts?.length > 0) {
    const c: any[] = [];
    selected.parts.forEach((v: PartType) => {
      c.push({ label: capitalize(v.name), bucketKey: v.key });
    });
    c.sort(sortByLabel);
    channels.push({
      label: "Original",
      http: selected.http_link,
      bucketKey: "",
    });
    c.forEach((v: any) => channels.push(v));
  }
  return channels;
}

function ConfirmationModal({
  show,
  onConfirm,
  onCancel,
}: {
  show: any;
  onConfirm: any;
  onCancel: any;
}) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>No selected audio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="mb-4">Do you want to proceed?</h5>
        <div className="justify-content-between">
          <button
            className="btn btn-primary"
            style={{
              color: "white",
              backgroundColor: "#FF30C4",
              borderColor: "#FF30C4",
              marginRight: "10px",
            }}
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="btn btn-primary"
            style={{
              color: "white",
              backgroundColor: "black",
              borderColor: "white",
            }}
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function sortByLabel(a: { label: string }, b: { label: string }) {
  const t1 = a.label.toUpperCase();
  const t2 = b.label.toUpperCase();
  return t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
}

function parsePartHttp(key: string) {
  const environment = "dev";
  return `https://muze-mvp-${environment}-audio-assets.s3.us-east-2.amazonaws.com/${key}`;
}

const capitalize = (s: string) =>
  s[0].toUpperCase() + s.substring(1).toLowerCase();

const configItem =
  (active: number, setActive: Function) => (i: any, k: number) => ({
    ...i,
    active: active === k,
    onClick: () => setActive(k),
    key: k,
  });

type PartType = {
  key: string;
  name: string;
  model: string;
};

type ChannelsType = { label: string; http: string; bucketKey: string };

function Header() {
  const subtitle = `Automated audio reactive will be overridden if you choose to manually map your audio to camera movements and transitions`;
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div
      className={`text-center ${
        window.innerWidth < 1600 ? "" : "m-0 p-1 mt-1"
      } p-2 mt-3`}
    >
      {!isMobileDevice() && <h1 className="m-0 p-0">Audio</h1>}
      {isMobileDevice() && <h1 className="m-0 p-0">🎶 Audio</h1>}
      <p className="m-0 p-0 text-muted">{subtitle}</p>
    </div>
  );
}

function Content() {
  const [active, setActive] = useState(0);
  const [showPlayer, setShowPlayer] = useState(false);
  const [loading, setLoading] = useState(false);
  const audio = useAppSelector(selectAudioAsset);
  const refAudio = useAppSelector(selectTempAudio);
  const hasMusic =
    Object.keys(audio.selected)?.includes("size") ||
    refAudio.name?.includes("mp3");
  const { setAudioDuration } = useAudioTrim();

  const { selected } = useAppSelector(selectAudioAsset);
  const { name } = useAppSelector(selectTempAudio);

  const [duration, setDuration] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (audio.end > 0) {
      setDuration(audio.end.toString());
    }
  }, [audio.end]);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setDuration(value);

    if (value && Number(value) < 0) {
      setError("The duration cannot be negative");
    } else {
      setError("");
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const durationValue = Number(duration);

    if (durationValue < 0) {
      setError("Please, insert a valid duration");
      return;
    }

    if (durationValue > 240) {
      setError("The duration cannot be longer than 240 seconds");
      return;
    }

    const audioDurationReference = {
      start: 0,
      end: durationValue,
    };

    dispatch(setAudioDuration(audioDurationReference));

    setDuration("");
  };

  useEffect(() => {
    if (selected && !name) {
      setShowPlayer(true);
    }
  }, [selected, name]);

  const channels = populateNav(selected);

  const ops = channels.map(configItem(active, setActive));

  const downloadVideo = async (url: any) => {
    setLoading(true);
    const link = document.createElement("a");
    link.href = url;
    link.download = link.href;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const players = channels.map((v, index) => (
    <TrimPlayer
      key={index}
      url={v.label === "Original" ? v.http : parsePartHttp(v.bucketKey)}
      active={active === index}
      index={index}
    />
  ));

  return (
    <div className="d-flex overflow-hidden">
      <div className="d-flex flex-column col-12 overflow-auto">
        {showPlayer && (
          <div className="d-flex flex-column p-3 gap-3 border rounded">
            {hasMusic && (
              <h5 className="m-0 p-0 text-white">Select video start and end</h5>
            )}
            {!hasMusic && (
              <div className="video-duration-input">
                <h5 className="m-0 p-0 text-white">
                  Select video duration in seconds
                </h5>

                <form onSubmit={handleSubmit} className="input-form">
                  <input
                    type="number"
                    value={duration}
                    onChange={handleInputChange}
                    min="0"
                    max="240"
                    placeholder="Duration in Seconds"
                    className={`form-control ${error ? "is-invalid" : ""}`}
                  />
                  {error && <div className="error-message">{error}</div>}
                  <button
                    type="submit"
                    style={{ backgroundColor: "#ff30c4", border: "#ff30c4" }}
                    className="btn btn-primary mt-2"
                  >
                    Confirm
                  </button>
                </form>
              </div>
            )}
            <Navigator {...{ ops, setActive }} />
            {players}
            {active !== 0 && channels[active].label !== "Original" && (
              <div
                onClick={() =>
                  downloadVideo(parsePartHttp(channels[active].bucketKey))
                }
                className="create-video-btn btn"
              >
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    alignContent: "center",
                    marginRight: "5px",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Export my stem"}
                </button>
                <i
                  className="bi bi-cloud-download-fill"
                  style={{
                    fontSize: "20px",
                    borderRadius: "10px",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                ></i>
              </div>
            )}
          </div>
        )}
        <div className="mt-3">
          <AudioReactivity />
        </div>
        <AdvancedSettings showPlayer={showPlayer} />
      </div>
    </div>
  );
}

function AdvancedSettings({ showPlayer }: { showPlayer: boolean }) {
  const [isShowing, setIsShowing] = useState(false);
  const [showAdvancedToast, setAdvancedToast] = useState(false);

  return (
    <div className="d-flex flex-column gap-3 mb-3">
      <AdvancedSettingsBtn
        {...{ isShowing, setIsShowing, setAdvancedToast, showPlayer }}
      />
      {showPlayer && isShowing && <ASettingsBox />}

      {showAdvancedToast && (
        <AdvancedToast onClose={() => setAdvancedToast(false)} />
      )}
    </div>
  );
}

function AdvancedSettingsBtn(props: any) {
  const { isShowing, setIsShowing, setAdvancedToast, showPlayer } = props;

  const handleClick = () => {
    if (!showPlayer) {
      setAdvancedToast(true);
    }
    setIsShowing(!isShowing);
  };

  return (
    <div className="d-flex gap-3" onClick={handleClick}>
      <i className={`bi bi-chevron-${isShowing ? "up" : "down"}`} />
      <span className="text-muted">Advanced Set Camera Movements</span>
    </div>
  );
}

function AdvancedToast(props: any) {
  const { showAdvancedToast, onClose } = props;

  return (
    <Toast show={showAdvancedToast} onClose={onClose}>
      <Toast.Header>
        <i
          className="bi bi-info-circle"
          style={{ fontSize: "1rem", marginRight: "8px" }}
        />
        <strong className="me-auto">Muze</strong>
      </Toast.Header>
      <Toast.Body>
        Advanced is available only when audio that has finished processing
      </Toast.Body>
    </Toast>
  );
}

function ASettingsBox() {
  return (
    <>
      {/* <MSetCameraMovements /> */}
      <MSetTransitionEffects />
    </>
  );
}
