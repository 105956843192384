import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

const initialSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Nightlife in a street party in Brazil",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Close up of a beautiful Brazilian woman smiling",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Crazy woman laughing",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const secondSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "The Ganges flowing between the Hindu temples of Varanasi",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Zoom out to the view of the Himalaya mountains",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Shiva sitting on top of Mount Kailash meditating",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const thirdSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Hip hop dancers, new york street, latino, sunny, fisheye lens",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Low rider cars, pink cadillac , new york street, latino, sunny",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Skateboarders, skaters, miami street, latino, sunny, fisheye lens",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const fourthSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Masquerade ball, night, anime, colorful, crowd parade",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label:
  //     "Mexico night parade, day of the dead, skeletons, anime, colorful, crowd parade",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label:
  //     "Las vegas showgirls, stage, broadway, festival, anime, colorful, crowd parade",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const fifthSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Crazy woman laughing",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Sun turning into a smiling face",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Sun setting on snowy mountains",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const sixthSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Delicate black and white flowers sprouting around a lonely robot",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Lonely robot sitting on a cloud",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Lonely robot calm and smiling",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const seventhSubjectMatter = [
  {
    time: "00:00",
    label: "",
    error: false,
    negativePrompt: "",
  },
  // {
  //   time: "00:00",
  //   label: "Flowers sprouting around an Amazonian Shaman",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label:
  //     "Colorful parrots flying around shamans praying in the Amazon forest",
  //   error: false,
  //   negativePrompt: "",
  // },
  // {
  //   time: "00:00",
  //   label: "Great serpent appearing in the sky",
  //   error: false,
  //   negativePrompt: "",
  // },
];

const prompts = [
  initialSubjectMatter,
  secondSubjectMatter,
  thirdSubjectMatter,
  fourthSubjectMatter,
  fifthSubjectMatter,
  sixthSubjectMatter,
  seventhSubjectMatter,
];

const index = Math.floor(Math.random() * prompts.length);

const initialState = { subjectMatter: prompts[index] };

const handleSubjectPromptsSlice = createSlice({
  name: "handleSubjectPrompts",
  initialState,
  reducers: {
    setSubject: (state, action) => ({
      ...state,
      subjectMatter: action.payload,
    }),
    resetSubject: () => ({
      subjectMatter: prompts[index],
    }),
  },
});

export const { setSubject, resetSubject } = handleSubjectPromptsSlice.actions;

export const selectSubjects = (state: RootState) =>
  state.subjectsMatter.subjectMatter;

export default handleSubjectPromptsSlice.reducer;
