import axios from "axios";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import PopUp from "../SharedComponents/PopUp";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import ReactPlayer from "react-player";
import { APIClient } from "../../utils/services";
import "./styles.css";
import { Cursor } from "react-bootstrap-icons";

export default function Videos() {
  const [videos, setVideos] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasVideos, setHasVideos] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [showVideoModal, setShowVideoModal] = useState(false); // Novo estado
  const [currentVideo, setCurrentVideo] = useState(""); // Novo estado
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const { currentUser } = useAuth();
  const [search] = useSearchParams();
  const query = search.get("query");
  const ownerId = currentUser?.email || "";
  const { REACT_APP_MUZE_API_HOST, REACT_APP_EXTERNAL_VIDEOS_CDN_URL } =
    process.env;

  const page = actual;
  const perPage = 10;

  const deleteVideos = (name: string) => {
    const handleDelete = () => {
      axios
        .delete(`${REACT_APP_MUZE_API_HOST}/external-video/${ownerId}/${name}`)
        .then((res) => {
          const newData = videos.filter((el: any) => el.name !== name);
          setVideos(newData);
          setVideoData(newData);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        })
        .finally(() => {
          setShow(false);
        });
    };

    setModalInfo({
      shouldShow: true,
      title: "Delete video",
      text: "Are you sure you want to delete this video?",
      confirmText: "Delete",
      onConfirm: () => handleDelete(),
      onClose: () => setShow(false),
    });
    setShow(true);
  };

  const getVideos = () => {
    setLoading(true);
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/external-video/assets?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setLoading(false);
          setHasVideos(false);
          setTotal(0);
          return;
        }
        const formatedVideos = res.data.results.map((el: any) => {
          return {
            src: `${REACT_APP_EXTERNAL_VIDEOS_CDN_URL}/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
          };
        });
        setVideos(formatedVideos);
        setVideoData(formatedVideos);
        setHasVideos(true);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useCustomEventListener("onVideoUpload", () => {
    getVideos();
  });

  useEffect(() => {
    getVideos();
  }, [actual]);

  useEffect(() => {
    setVideos(() =>
      videoData.filter((el: any) =>
        el.name.toLowerCase().includes(query?.toLowerCase())
      )
    );
  }, [query]);

  return (
    <div className="overflow-auto">
      <div className="library-img-conteiner overflow-auto">
        {show && <PopUp {...modalInfo} />}
        {showVideoModal && (
          <div className="video-modal">
            <div
              onClick={() => setShowVideoModal(false)}
              className="video-modal-content"
              style={{ cursor: "pointer" }}
            >
              <button className="close-btn">
                <i className="bi bi-x"></i>
              </button>
              <ReactPlayer
                url={currentVideo}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        )}
        {error ? (
          "Ops, something went wrong :("
        ) : loading ? (
          <Spinner />
        ) : !hasVideos ? (
          "0 videos found."
        ) : (
          videos.map(({ name, src, date }, i) => (
            <Card
              name={name}
              src={src}
              date={date}
              key={`media-card-${i}`}
              deleteVideos={deleteVideos}
              onClick={() => {
                setCurrentVideo(src);
                setShowVideoModal(true);
              }}
            />
          ))
        )}
      </div>
      <div className="paginator-container">
        <Paginator {...paginator} />
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "2rem", height: "2rem" }}
        role="status"
      />
    </div>
  );
}

function Card(props: {
  name: string;
  src: string;
  date: string;
  deleteVideos: any;
  onClick: () => void; // Novo prop
}) {
  const navigate = useNavigate();
  const { name, src, date, deleteVideos, onClick } = props;
  const isVideo =
    src.endsWith(".mp4") || src.endsWith(".avi") || src.endsWith(".mov");

  const [videoDimensions, setVideoDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    async function fetchVideoDimensions() {
      try {
        const response = await fetch(src);
        const blob = await response.blob();
        const dimensions = await getVideoDimensions(blob);
        console.log(dimensions);
        setVideoDimensions(dimensions);
      } catch (error) {
        console.error("Error fetching video dimensions:", error);
      }
    }

    if (isVideo) {
      fetchVideoDimensions();
    }
  }, [src, isVideo]);

  const getVideoDimensions = async (
    file: Blob
  ): Promise<{ width: number; height: number }> => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.src = URL.createObjectURL(file);
    });
  };

  const handleDeleteClick = (event: any) => {
    event.stopPropagation();
    deleteVideos(name);
  };

  const cardStyle = {
    width: "10rem",
    height: "10rem",
    transition: "border-color 0.3s ease",
    cursor: "pointer",
    borderRadius: "0.5rem",
    borderColor: "#ccc",
    position: "relative" as "relative",
    overflow: "hidden",
  };

  const deleteIconStyle = {
    position: "absolute" as "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    zIndex: 1,
  };

  const nameStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "5px",
    left: "5px",
    right: "5px",
    color: "#fff",
    padding: "2px 5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
  };

  const dateStyle: React.CSSProperties = {
    position: "relative",
    left: "5px",
    right: "5px",
    color: "#fff",
    padding: "2px 5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.8rem",
  };

  return (
    <div
      className="rounded border text-center"
      style={cardStyle}
      onClick={onClick}
    >
      <div style={dateStyle}>{date}</div>
      <i
        className="bi bi-trash"
        style={deleteIconStyle}
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteClick(e);
        }}
      ></i>
      {isVideo ? (
        <ReactPlayer
          url={src}
          width="100%"
          height="100%"
          style={{ maxHeight: "11rem" }}
        />
      ) : (
        <img className="library-img" src={src} alt={name} />
      )}
      <div style={nameStyle}>{name}</div>
    </div>
  );
}
