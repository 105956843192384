import AuthProvider, { useAuth } from "../AuthProvider";
import { Provider } from "react-redux";
import { pallete } from "../utils";
import { store } from "../store";
import {
  Routes,
  Route,
  Outlet,
  Link,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { setIntercomUserData } from "../utils/utils";

import mixpanel from "mixpanel-browser";

import AudioReactivityPage from "../components/AudioReactivityPage";
import ChooseArtStylePage from "../components/ChooseArtStylePage";
import RestyleVideoPage from "../components/RestyleVideoPage";
import ImageToVideoPage from "../components/ImageToVideoPage";
import CreateAvatarPage from "../components/CreateAvatarPage";
import CreateVideoPage from "../components/CreateVideoPage";
import DashboardPage from "../components/DashboardPage";
import TemplatePage from "../components/TemplatePage";
import ProjectsPage from "../components/ProjectsPage";
import PromptsPage from "../components/PromptsPage";
import PreviewPage from "../components/PreviewPage";
import UpscalePage from "../components/UpscalePage";
import EditProfile from "../components/Settings/EditProfile";
import ComingSoon from "../components/ComingSoon";
import Separator from "../components/Login/Separator";
import ImagePage from "../components/ImagePage";
import AudioPage from "../components/AudioPage";
import Navigator from "../components/Navigator";
import UserCard from "../components/UserCard";
import Settings from "../components/Settings";
import Billing from "../components/Settings/Billing";
import Pricing from "../components/Settings/Pricing";
import Admin from "../components/Settings/Admin";
import Login from "../components/Login";
import Steps from "../components/Steps";
import Logo from "../assets/Logo.png";

import "./styles.css";
import Projects from "../components/Library/Projects";
import Audios from "../components/Library/Audios";
import Videos from "../components/Library/Videos";
import Avatars from "../components/Library/Avatars";
import Images from "../components/Library/Images";
import AudioTrim from "../components/AudioTrim";
import { APIClient } from "../utils/services";
import { useEffect, useState } from "react";
import VideosUploaded from "../components/RestyleVideoPage/VideosUploaded";
import RestylePage from "../components/RestyleVideoPage/RestylePage";
import CreateImagePage from "../components/CreateImagePage";
import Styles from "../components/Library/Styles";
import RestyledUploaded from "../components/RestyleVideoPage/RestyledUploaded";
import SpotifyCanvasGenerator from "../components/SpotifyCanvasGenerator";
import ArtStyle from "../components/SpotifyCanvasGenerator/ArtStyle";
import SuccessfulPaymentPage from "../components/SuccessfulPaymentPage";
import AssetsLibrary from "../components/AssetsLibrary";
import Ipadapter from "../components/Library/Ipadapter";

const { primary02, accents02, accents03, accents04 } = pallete;

// TODO: replace this code on a component
// HAVING TROUBLE  WITH HOT RELOAD WITH THIS CODE:
// let userId: any = window.localStorage.getItem(
//   "firebase:authUser:AIzaSyD37EVrLn_NQ6wD-Slh-hyWnSCp4nPAVBg:[DEFAULT]"
// );
// userId = JSON.parse(userId)?.uid;

// if (userId) {
//   const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
//   mixpanel.init(MIXPANEL_TOKEN, {
//     debug: true,
//     track_pageview: true,
//     persistence: "localStorage",
//   });
//   mixpanel.identify(userId);
//   mixpanel.track("Sign Up", {
//     "Signup Type": "#google-signin",
//   });
// }

function Layout() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className={isMobileDevice() ? "" : "vh-100 d-flex"}>
      <Outlet />
    </div>
  );
}

function Main() {
  const n = useNavigate();
  const { currentUser } = useAuth();
  const userId = currentUser?.email || "";
  const client = new APIClient();
  const [dataPhoto, setDataPhoto] = useState("");
  const [dataName, setDataName] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const { pathname } = useLocation();
  const screenWidth = window.innerWidth;

  const shouldApplyResponsiveness =
    pathname === "/app/purchase" && screenWidth < 1700;

  const handleLogoClick = () => {
    n("/app/dashboard");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const fetchData = async () => {
    try {
      const userData = await client.getUserData(userId);
      setDataPhoto(userData?.photo_url);
      setDataName(userData?.user_name);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  if (!currentUser) {
    return <Login />;
  }

  return isMobileDevice() ? (
    <div onClick={fetchData}>
      <div className="d-flex flex-column">
        <div
          style={{ backgroundColor: "#ff30c4" }}
          className="d-flex align-items-center justify-content-between"
        >
          <img
            alt="logo"
            src={Logo}
            onClick={handleLogoClick}
            style={{ cursor: "pointer", marginLeft: "11px" }}
          />
          <i
            className="bi bi-list fs-1 btn"
            style={{ cursor: "pointer" }}
            onClick={toggleMenu}
          />
        </div>
        {menuOpen && (
          <header className="border-bottom p-2">
            <Navigator
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
              onItemClick={(route: any) => {
                setMenuOpen(false);
                n(route);
              }}
            />
            <Separator />
            <MenuItemSettingsResponsiveness
              icon="bi-gear"
              text="Settings"
              onClick={() => {
                setMenuOpen(!menuOpen);
                n("/app/settings");
              }}
            />
            <UserCard dataName={dataName} dataPhoto={dataPhoto} />
          </header>
        )}
        {!menuOpen && (
          <div className="flex-grow-1 d-flex">
            {/* Content goes here */}
            <Outlet />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="flex-grow-1 d-flex" onClick={fetchData}>
      <div className="border-end d-flex flex-column gap-2 p-2">
        <header className="d-flex align-items-center mb-3 justify-content-between">
          <img
            alt="logo"
            src={Logo}
            onClick={handleLogoClick}
            style={{ cursor: "pointer" }}
          />
          <i
            className="bi bi-gear fs-3 btn"
            style={{ cursor: "pointer" }}
            onClick={() => n("/app/settings")}
          />
        </header>
        <Navigator />
        <Separator />
        <Steps />
        <UserCard dataName={dataName} dataPhoto={dataPhoto} />
      </div>
      <Outlet />
    </div>
  );
}

interface MenuItemWithTextProps {
  icon: string;
  text: string;
  onClick: () => void;
}

function MenuItemSettingsResponsiveness({
  icon,
  text,
  onClick,
}: MenuItemWithTextProps) {
  return (
    <div
      style={{ cursor: "pointer" }}
      className="d-flex align-items-center text-start"
      onClick={onClick}
    >
      <i
        className={`bi ${icon} fs-3 btn`}
        style={{ cursor: "pointer", marginRight: "5px" }}
      />
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        {text}
      </div>
    </div>
  );
}

function NotFound() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 flex-column gap-5">
      Not Found 404
      <Link className="btn btn-dark" to="/">
        back to app
      </Link>
    </div>
  );
}

export default function App() {
  setIntercomUserData();
  setIntercomUserData();

  return (
    <AuthProvider>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />
            <Route path="purchase/:plan" index element={<Login />} />
            <Route path="app" element={<Main />}>
              <Route path="purchase" element={<Pricing />} />
              <Route index element={<Navigate to="dashboard" />} />
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="template" element={<TemplatePage />} />
              <Route path="upscale-video" element={<UpscalePage />} />
              <Route path="create-video" element={<CreateVideoPage />}>
                <Route index element={<Navigate to="audio-select" />} />
                <Route path="audio-select" element={<AudioPage />} />
                <Route path="trim" element={<AudioTrim />} />
                <Route
                  path="audio-reactivity"
                  element={<AudioReactivityPage />}
                />
                <Route
                  path="choose-art-style"
                  element={<ChooseArtStylePage />}
                />
                <Route path="prompts" element={<PromptsPage />} />
                <Route path="preview-video" element={<PreviewPage />} />
                <Route path="upscale-video" element={<UpscalePage />} />
              </Route>
              <Route path="create-an-image" element={<CreateImagePage />} />
              <Route path="billing" element={<Billing />} />
              <Route path="pricing" element={<Pricing />} />
              <Route
                path="payment-successful"
                element={<SuccessfulPaymentPage />}
              />

              <Route path="restyle-video" element={<RestyleVideoPage />}>
                <Route index element={<Navigate to="original-videos" />} />
                <Route path="original-videos" element={<VideosUploaded />} />
              </Route>
              <Route path="restyle">
                <Route index element={<RestylePage />} />
              </Route>
              <Route path="image-to-video" element={<ImageToVideoPage />}>
                <Route index element={<Navigate to="image-select" />} />
                <Route path="image-select" element={<ImagePage />} />
                <Route path="audio-select" element={<AudioPage />} />
                <Route
                  path="audio-reactivity"
                  element={<AudioReactivityPage />}
                />
                <Route
                  path="choose-art-style"
                  element={<ChooseArtStylePage />}
                />
                <Route path="prompts" element={<PromptsPage />} />
                <Route path="preview-video" element={<PreviewPage />} />
                <Route path="upscale-video" element={<UpscalePage />} />
              </Route>

              <Route path="create-avatar" element={<CreateAvatarPage />} />
              <Route
                path="spotify-generator"
                element={<SpotifyCanvasGenerator />}
              />
              <Route path="art-style" element={<ArtStyle />} />
              <Route path="library" element={<ProjectsPage />}>
                <Route index element={<Navigate to="projects" />} />
                <Route path="projects" element={<Projects />} />
                <Route path="uploaded-audios" element={<Audios />} />
                <Route path="uploaded-images" element={<Images />} />
                <Route path="uploaded-videos" element={<Videos />} />
                <Route path="avatars" element={<Avatars />} />
                <Route path="restyle-videos" element={<RestyledUploaded />} />
              </Route>
              <Route path="assets-library" element={<AssetsLibrary />}>
                <Route index element={<Navigate to="uploaded-audios" />} />
                <Route path="uploaded-audios" element={<Audios />} />
                <Route path="uploaded-images" element={<Images />} />
                <Route path="uploaded-ipadapter" element={<Ipadapter />} />
                <Route path="uploaded-videos" element={<Videos />} />
              </Route>
              <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate to="edit-profile" />} />
                <Route path="edit-profile" element={<EditProfile />} />
                <Route path="admin" element={<Admin />} />
              </Route>
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="dev" element={<ComingSoon />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          {/* <Route path="purchase" element={<Pricing />} /> */}
        </Routes>
      </Provider>
    </AuthProvider>
  );
}
