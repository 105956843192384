import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import App from "./routes/App";
import { Toaster } from "react-hot-toast";
import AuthProvider, { useAuth } from "./AuthProvider"; // importing UseAuth

// Inicialização do Sentry
Sentry.init({
  dsn: "https://5210f700285dc9fb19a48774466488fb@o4507255649402880.ingest.us.sentry.io/4507271639072768",
  environment: "development",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // "localhost",
    // "http://localhost:3000",
    // // "https://j7pbseagji.execute-api.us-east-1.amazonaws.com",
    // // "https://j7pbseagji.execute-api.us-east-1.amazonaws.com/",
    "https://zcuru88na4.execute-api.us-east-2.amazonaws.com",
    "https://zcuru88na4.execute-api.us-east-2.amazonaws.com/",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <AppWithSentry />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

function AppWithSentry() {
  const { currentUser } = useAuth();

  const userEmail = currentUser?.email || "";

  Sentry.setUser({ email: userEmail });

  return (
    <>
      <Toaster />
      <App />
    </>
  );
}
