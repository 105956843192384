import axios from 'axios';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getMultiFactorResolver,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

const baseURL = process.env.REACT_APP_MUZE_API_HOST || 'localhost:8002';

const axiosConfig = {
  baseURL,
  timeout: 360000,
};
const axiosInstance = axios.create(axiosConfig);

const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_DATABASE_URL,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_APP_ID,
  REACT_APP_MEASUREMENT_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DATABASE_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

async function checkCode(
  resolver: MultiFactorResolver,
  verificationId: any,
  verificationCode: any
) {
  return resolver.resolveSignIn(
    PhoneMultiFactorGenerator.assertion(
      PhoneAuthProvider.credential(verificationId, verificationCode)
    )
  );
}

async function getUserData(
  email: string,
  password: string,
  idToken: any,
  setToken: Function,
  auth: any
) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
  // const res = await axiosInstance.post('/login', { email, password }); // muse dont have a /login endpoint
  const res = auth.currentUser;

  localStorage.setItem('access_token', idToken);
  setToken(`access_token:${idToken}`);
  return { ...res, token: idToken };
}

export function createUser(
  email: string,
  password: string,
  okFunc: Function,
  errFunc = console.error
) {
  const auth = getAuth(app);
  createUserWithEmailAndPassword(auth, email, password)
    .then((r) => okFunc(r))
    .catch(errFunc);
}

export function deleteUserFB(
  password: string,
  okFunc: VoidFunction,
  errFunc = console.error
) {
  const auth = getAuth(app);

  reauthenticateWithCredential(
    auth.currentUser!,
    EmailAuthProvider.credential(auth.currentUser?.email!, password)
  )
    .then(() => deleteUser(auth.currentUser!).then(okFunc).catch(errFunc))
    .catch(errFunc);
}

async function loginPassword(
  email: string,
  password: string,
  code: string,
  data: any,
  setToken: Function
) {
  const auth = getAuth(app);
  const recaptchaVerifier = new RecaptchaVerifier(
    'loggin-btn',
    { size: 'invisible' },
    auth
  );
  const sendCode = async (error: any) => {
    const resolver = getMultiFactorResolver(auth, error);
    if (resolver?.hints[0].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
      return {
        error: 'The selected multifactor authenticator is not supported',
      };
    }
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
    return { verificationId, resolver };
  };

  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )
      .then(async () => {
        const idToken = await auth.currentUser?.getIdToken(true);

        if (!idToken) throw new Error('Could not generate token');

        return getUserData(email, password, idToken, setToken, auth);
      })
      .catch(async (err) => {
        if (err?.code === 'auth/multi-factor-auth-required' && !code) {
          const data = await sendCode(err);
          if (data.error) throw new Error(data.error);

          return { error: 'MFA', data };
        }

        if (data.resolver) {
          await checkCode(data.resolver, data.verificationId, code);
          const idToken = await auth.currentUser?.getIdToken(true);
          return getUserData(email, password, idToken, setToken, auth);
        }

        // probably credential error

      });

    if (userCredential?.error === 'MFA') recaptchaVerifier.clear();

    return userCredential;
  } catch (err) {
    recaptchaVerifier.clear();
    localStorage.removeItem('access_token');
    throw err;
  }
}

export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');

export default loginPassword;
