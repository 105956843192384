import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";
import {
  signInWithEmail,
  signInWithGoogle,
  sendPasswordReset,
} from "../../AuthProvider/authFunctions";
import { APIClient } from "../../utils/services";
import Logo from "../../assets/Logo.png";
import { pallete } from "../../utils";
import { setIntercomUserData } from "../../utils/utils";
import { analytics, auth } from "../../AuthProvider/firebaseConfig";
import { logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import LoginImage from "./login.png";
import "./style.css";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "@firebase/auth";

import toast from "react-hot-toast";
import ModalSignUp from "./ModalSignUp";
import { useCheckout } from "../Subscription/useCheckout";
import ModalStyle from "../Subscription/modalStyle";
import ModalResendEmail from "./ModalResendEmail";
import { getAuth } from "firebase/auth";
const { bright } = pallete;
function Login() {
  const gotoGV = (v: any) => {
    if (v.name === "checkout.completed") {
      setShowSuccessFirstSubs(true);
      navigate("/app/dashboard");
    }
  };

  const { openPaddleCheckout, setShowSuccessFirstSubs, showSuccessFirstSubs } =
    useCheckout(gotoGV);
  const location = useLocation();

  const redirectToDashboardOrPurchase = (
    ownerId: string,
    priceId: string,
    location: any
  ) => {
    const { pathname } = location;

    if (pathname.startsWith("/purchase/")) {
      const plan = pathname.split("/")[2];

      switch (plan) {
        case "agency":
          priceId = "pri_01hdefn575zmc1179h37ekvv9q";
          break;
        case "enterprise":
          priceId = "pri_01hdefppc5rsf52ren5q9r96yc";
          break;
        case "rockstar":
          priceId = "pri_01hdm0z54mf9wabp9q4v0ve4gh";
          break;
        default:
          break;
      }

      openPaddleCheckout(ownerId, priceId);
    } else {
      navigate("/app/dashboard");
    }
  };

  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [passSignUp, setPassSignUp] = useState("");
  const [email, setEmail] = useState("");
  const [color, setColor] = useState("red");
  const [strengthRules, setStrengthRules] = useState({
    minLength: false,
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [activeButton, setActiveButton] = useState("signin");
  const [showIndicator, setShowIndicator] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [showErrorMailVerification, setShowErrorMailVerification] =
    useState(false);
  const [showErrorManyRequests, setShowErrorManyRequests] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const BASE_URL =
    process.env.REACT_APP_MUZE_API_HOST || "http://localhost:8002";

  useEffect(() => {
    const validateStrength = () => {
      const minLength = passSignUp.length >= 8;
      const hasLowerCase = /[a-z]/.test(passSignUp);
      const hasUpperCase = /[A-Z]/.test(passSignUp);
      const hasNumber = /\d/.test(passSignUp);
      const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
        passSignUp
      );

      setStrengthRules({
        minLength,
        hasLowerCase,
        hasUpperCase,
        hasNumber,
        hasSpecialChar,
      });

      setPasswordsMatch(passSignUp === confirmPass);
    };

    validateStrength();
  }, [passSignUp, confirmPass]);

  useEffect(() => {
    const isFormValid =
      !invalid &&
      (activeButton === "createaccount"
        ? strengthRules.minLength &&
          strengthRules.hasLowerCase &&
          strengthRules.hasNumber &&
          strengthRules.hasSpecialChar &&
          strengthRules.hasUpperCase &&
          confirmPass !== "" &&
          passSignUp === confirmPass
        : activeButton === "signin" && !invalid);

    const button = document.querySelector<HTMLButtonElement>(".btn.loggin-btn");

    if (button) {
      button.disabled = !isFormValid;
    }
  }, [
    invalid,
    emailExistsError,
    inactive,
    strengthRules,
    confirmPass,
    passSignUp,
    activeButton,
  ]);

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const client = new APIClient();
  client.authenticate();
  const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
  mixpanel.init(MIXPANEL_TOKEN);
  let result;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = queryParams.get("id");

    if (checkoutSessionId) {
      const fetchSession = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/api/checkout/session/${checkoutSessionId}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch session");
          }
          const email = await response.json();

          if (email) {
            const userExists = await checkUserInFirebase(email);

            if (userExists) {
              setActiveButton("signin");
              setIsSignIn(true);
              setEmail(email);
            } else {
              setActiveButton("createaccount");
              setShowConfirmPassword(true);

              setIsSignUp(true);
              setEmail(email);
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          console.error("Fetch Session Error:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchSession();
    } else {
      console.log("session id not found");
      setLoading(false);
    }
  }, []);

  const checkUserInFirebase = async (email: string) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/check-user?email=${encodeURIComponent(email)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        throw new Error("Failed to check user in Firebase");
      }
    } catch (error) {
      console.error("Error checking user in Firebase:", error);
      return false;
    }
  };

  const callback = async (email: any, name?: any) => {
    setIntercomUserData();
    try {
      const userExists = await client.getUserByEmail(email);
      if (!userExists) {
        await client.register_new_user_stripe(email, name);
        logEvent(analytics, "New Users");
        mixpanel.identify(email);
        mixpanel.people.set("$email", email);
        const firstName = getEmailFirstName(email);
        mixpanel.people.set("$name", firstName);
        redirectToDashboardOrPurchase(email, "", location);
        // navigate("app/dashboard");
      } else {
        logEvent(analytics, "Logins");
        mixpanel.track("Logins");
        mixpanel.identify(userExists.owner_id);
        mixpanel.people.set("$email", email);
        const firstName = getEmailFirstName(email);
        mixpanel.people.set("$name", firstName);
        mixpanel.people.set("$account", userExists.account_type);
        navigate("/app/dashboard");
      }
    } catch (error) {
      console.error("Error in callback:", error);
    }
  };

  const getEmailFirstName = (email: string) => {
    const emailParts = email.split("@");
    const username = emailParts[0];
    const firstName = username.charAt(0).toUpperCase() + username.slice(1);
    return firstName;
  };

  const handlePasswordChange = (e: any) => {
    if (activeButton === "signin") {
      setShowIndicator(false);
    }

    setPass(e.target.value);

    if (invalid) {
      setInvalid(false);
    }
    if (emailExistsError) {
      setEmailExistsError(false);
    }
    if (inactive) {
      setInactive(false);
    }
  };

  // Função para avaliar a força da senha
  const evaluatePasswordStrength = (passSignUp: any) => {
    let color = "red";
    let updatedStrengthRules = {
      minLength: passSignUp.length >= 8,
      hasLowerCase: /[a-z]/.test(passSignUp),
      hasUpperCase: /[A-Z]/.test(passSignUp),
      hasNumber: /\d/.test(passSignUp),
      hasSpecialChar: /[!@#$%^&*]/.test(passSignUp),
    };

    if (
      updatedStrengthRules.hasLowerCase &&
      updatedStrengthRules.hasUpperCase &&
      updatedStrengthRules.hasNumber &&
      updatedStrengthRules.hasSpecialChar &&
      updatedStrengthRules.minLength
    ) {
      color = "green";
    }
    setColor(color);
    setStrengthRules(updatedStrengthRules);
  };

  const handlePasswordChangeSignUp = (e: any) => {
    const password = e.target.value;
    setPassSignUp(password);

    if (invalid) {
      setInvalid(false);
    }
    if (emailExistsError) {
      setEmailExistsError(false);
    }
    if (inactive) {
      setInactive(false);
    }
    evaluatePasswordStrength(passSignUp);
    setShowIndicator(true);
  };

  useEffect(() => {
    if (activeButton === "createaccount" && confirmPass !== passSignUp) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }
  }, [confirmPass, passSignUp, activeButton]);

  const handleConfirmPasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setConfirmPass(newPassword);
    setShowIndicator(false);
  };

  const createUser = (
    email: any,
    password: any,
    successCallback: any,
    errorCallback: any
  ) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        successCallback(user);
      })
      .catch((error) => {
        const errorMessage =
          error.code === "auth/email-already-in-use"
            ? "This email already exists"
            : error.message;
        errorCallback(errorMessage);
        if (error.code === "auth/email-already-in-use") {
          setEmailExistsError(true); // Set the new state variable
        } else {
          setInvalid(true);
        }
      });
  };

  const sendVerificationEmail = async (user: any) => {
    try {
      const actionCodeSettings = {
        url: "https://app-dev.muzeart.ai/",
        handleCodeInApp: false,
      };
      await sendEmailVerification(user, actionCodeSettings);
      setShowSuccessModal(true);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const reSendVerificationEmail = async (user: any) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser) {
        const actionCodeSettings = {
          url: "https://app-dev.muzeart.ai/",
          handleCodeInApp: false,
        };

        await sendEmailVerification(currentUser, actionCodeSettings);

        setShowErrorModal(false);
        setShowSuccessModal(true);
      } else {
        console.error("User is not authenticated");
      }
    } catch (error: any) {
      if (error.code === "auth/too-many-requests") {
        setShowErrorManyRequests(true);
      } else {
        console.error(error.message);
        setShowErrorMailVerification(true);
      }
    }
  };

  const checkUserEmailVerification = () => {
    const user = auth.currentUser;
    if (user) {
      if (user.emailVerified) {
        callback(auth.currentUser?.email, name);
      } else {
        setShowErrorModal(true);
      }
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (activeButton === "createaccount") {
      createUser(
        email,
        passSignUp,
        (user: any) => {
          sendVerificationEmail(user);
          setLoading(false);
        },
        (error: string) => {
          setEmailExistsError(true);
          setLoading(false);
        }
      );
    } else {
      signInWithEmail(
        email,
        pass,
        () => {
          checkUserEmailVerification();
          setLoading(false);
        },
        (error: string) => {
          if (error === "Your subscription is inactive.") {
            setInactive(true);
          } else {
            setInvalid(true);
          }
          setLoading(false);
        }
      );
    }
    setPass("");
    setPassSignUp("");
    setEmail("");
    setConfirmPass("");
  };

  const handleButtonClick = (buttonType: any) => {
    setActiveButton(buttonType);

    if (buttonType === "createaccount") {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
      setShowIndicator(false);
    }
  };
  const resetPasswordState = async (e: any) => {
    e.preventDefault();
    setResetPasswordRequested(true);
  };

  const handleSubmitForgotPassword = async (e: any) => {
    e.preventDefault();
    if (!email) {
      setInvalid(true);
      return;
    }
    sendPasswordReset(
      email,
      () => {
        setPasswordSuccess(true);
        setResetPasswordRequested(false);
        setActiveButton("signin");
      },
      (error) => {
        if (
          error.message === "INVALID_EMAIL" ||
          error.message === "auth/user-not-found" ||
          error.code === "auth/user-not-found"
        ) {
          toast.success(
            "Please check your email for the password reset link.",
            { duration: 7000 }
          );
          setResetPasswordRequested(false);
          setActiveButton("signin");
        }
      }
    );
  };

  const BackSignIn = () => {
    setActiveButton("signin");
    setShowSuccessModal(false);
    setShowErrorModal(false);
    setPasswordSuccess(false);
    setShowConfirmPassword(false);
    setShowErrorManyRequests(false);
    setShowErrorMailVerification(false);
  };

  const goToDashboard = () => {
    navigate("/app/dashboard");
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <Container
      fluid="md"
      className={`d-flex flex-column justify-content-center ${
        isMobileDevice() ? "p-1" : "p-4"
      }`}
      style={{ backgroundColor: isMobileDevice() ? "black" : "" }}
    >
      <Row className="flex-grow-1">
        <Col
          md={3}
          className={`d-flex flex-column justify-content-center ${
            isMobileDevice() ? "align-items-center" : ""
          }`}
          style={{
            marginRight: "25px",
            backgroundColor: isMobileDevice() ? "black" : "",
          }}
        >
          <h2>Turn music into video</h2>
          <p className={isMobileDevice() ? "text-center" : ""}>
            Audio reactive music videos powered by Generative AI
          </p>
          <img
            src={LoginImage}
            alt="image description"
            style={{
              width: isMobileDevice() ? "155px" : "100%",
              height: isMobileDevice() ? "155px" : "auto",
            }}
            className={isMobileDevice() ? "img-fluid" : ""}
          />
        </Col>
        <Col
          md={8}
          className="d-flex flex-column justify-content-center rounded"
          style={{
            backgroundColor: isMobileDevice() ? "black" : "#343839",
          }}
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-column m-auto">
              <div
                className={`d-flex flex-align-center justify-content-center ${
                  isMobileDevice() ? "mt-4" : ""
                } pb-4`}
              >
                <img alt="logo" src={Logo} />
              </div>
              {!resetPasswordRequested && (
                <div className="button-group-container">
                  {" "}
                  <ButtonGroup
                    aria-label="Basic example"
                    className="rounded btn-group"
                  >
                    <Button
                      variant="secondary"
                      className={
                        activeButton === "signin"
                          ? "custom-active btn-md"
                          : "custom-inactive btn-md"
                      }
                      onClick={() => handleButtonClick("signin")}
                      disabled={isSignUp}
                    >
                      Sign in
                    </Button>
                    <Button
                      variant="secondary"
                      className={
                        activeButton === "createaccount"
                          ? "custom-active btn-md"
                          : "custom-inactive btn-md"
                      }
                      onClick={() => handleButtonClick("createaccount")}
                      disabled={isSignIn}
                    >
                      Create account
                    </Button>
                  </ButtonGroup>
                </div>
              )}
              {!resetPasswordRequested && (
                <button
                  className="btn button-google"
                  style={{ marginTop: `${isMobileDevice() ? "12px" : "20px"}` }}
                  id="google-signin"
                  onClick={() => {
                    signInWithGoogle((email: string) => {
                      callback(email, name);
                    });
                  }}
                >
                  <img
                    src="https://img.icons8.com/fluency/48/google-logo.png"
                    alt="Google logo"
                    width="24"
                    height="24"
                    style={{ marginRight: "10px" }}
                  />
                  Login with Google
                </button>
              )}
              {!resetPasswordRequested && (
                <div
                  className={`py-${
                    isMobileDevice() ? "1" : "3"
                  } d-flex align-items-center`}
                >
                  {activeButton !== "createaccount" && (
                    <hr className="flex-grow-1" />
                  )}
                  {activeButton !== "createaccount" && (
                    <small className="badge text-muted fw-lighter px-4">
                      Or
                    </small>
                  )}
                  {activeButton !== "createaccount" && (
                    <hr className="flex-grow-1" />
                  )}
                </div>
              )}
              <form
                className={`d-flex flex-column ${
                  isMobileDevice() ? "gap-2" : "gap-3"
                } pb-3`}
                onSubmit={onSubmit}
              >
                {resetPasswordRequested ? (
                  <>
                    <h5
                      style={{
                        fontWeight: "lighter",
                      }}
                    >
                      <i
                        className="bi bi-arrow-left"
                        onClick={() => setResetPasswordRequested(false)}
                      ></i>{" "}
                      {/* --------- */}
                      Reset your password:
                    </h5>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-envelope"></i>
                      </span>
                      <input
                        required
                        type="email"
                        className={
                          invalid ? "invalid form-control" : "form-control"
                        }
                        placeholder="Email"
                        value={email}
                        onChange={(e: any) => {
                          setEmail(e.target.value);
                          if (invalid) {
                            setInvalid(false);
                          }
                          if (emailExistsError) {
                            setEmailExistsError(false);
                          }
                          if (inactive) {
                            setInactive(false);
                          }
                        }}
                        onBlur={() => {
                          if (email === "") setInvalid(true);
                        }}
                      />
                    </div>
                    {invalid && (
                      <p className="inv ps">Please enter an email address</p>
                    )}
                    <button
                      type="button"
                      onClick={handleSubmitForgotPassword}
                      className="btn loggin-btn"
                    >
                      Reset Password
                    </button>
                  </>
                ) : (
                  <>
                    {activeButton === "createaccount" && (
                      <div className="input-group"></div>
                    )}

                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-envelope"></i>
                      </span>
                      <input
                        required
                        type="email"
                        className={
                          invalid ? "invalid form-control" : "form-control"
                        }
                        placeholder="Email"
                        value={email}
                        onChange={(e: any) => {
                          setEmail(e.target.value);
                          if (invalid) {
                            setInvalid(false);
                          }
                          if (emailExistsError) {
                            setEmailExistsError(false);
                          }
                          if (inactive) {
                            setInactive(false);
                          }
                        }}
                        onBlur={() => {
                          if (email === "") setInvalid(true);
                        }}
                      />
                    </div>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="bi bi-lock"></i>
                      </span>
                      {activeButton === "createaccount" && (
                        <input
                          className={
                            invalid ? "invalid form-control" : "form-control"
                          }
                          type="password"
                          placeholder="Password"
                          value={passSignUp}
                          onChange={handlePasswordChangeSignUp}
                        />
                      )}
                      {activeButton === "signin" && (
                        <input
                          className={
                            invalid ? "invalid form-control" : "form-control"
                          }
                          type="password"
                          placeholder="Password"
                          value={pass}
                          onChange={handlePasswordChange}
                        />
                      )}
                    </div>
                    <div></div>
                    {showIndicator && passSignUp.length > 0 && (
                      <div className="strength-indicator mt-0">
                        <ul>
                          <li
                            style={{
                              color: strengthRules.minLength ? "green" : color,
                            }}
                          >
                            At least 8 characters long
                            <i
                              className={
                                strengthRules.minLength
                                  ? "bi bi-check-circle-fill"
                                  : "bi bi-x-circle-fill"
                              }
                              style={{ marginLeft: "5px" }}
                            ></i>
                          </li>
                          <li
                            style={{
                              color: strengthRules.hasLowerCase
                                ? "green"
                                : color,
                            }}
                          >
                            Contains at least one lowercase letter
                            <i
                              className={
                                strengthRules.hasLowerCase
                                  ? "bi bi-check-circle-fill"
                                  : "bi bi-x-circle-fill"
                              }
                              style={{ marginLeft: "5px" }}
                            ></i>
                          </li>
                          <li
                            style={{
                              color: strengthRules.hasUpperCase
                                ? "green"
                                : color,
                            }}
                          >
                            Contains at least one uppercase letter
                            <i
                              className={
                                strengthRules.hasUpperCase
                                  ? "bi bi-check-circle-fill"
                                  : "bi bi-x-circle-fill"
                              }
                              style={{ marginLeft: "5px" }}
                            ></i>
                          </li>
                          <li
                            style={{
                              color: strengthRules.hasNumber ? "green" : color,
                            }}
                          >
                            Contains at least one number
                            <i
                              className={
                                strengthRules.hasNumber
                                  ? "bi bi-check-circle-fill"
                                  : "bi bi-x-circle-fill"
                              }
                              style={{ marginLeft: "5px" }}
                            ></i>
                          </li>
                          <li
                            style={{
                              color: strengthRules.hasSpecialChar
                                ? "green"
                                : color,
                            }}
                          >
                            Contains at least one special character
                            <i
                              className={
                                strengthRules.hasSpecialChar
                                  ? "bi bi-check-circle-fill"
                                  : "bi bi-x-circle-fill"
                              }
                              style={{ marginLeft: "5px" }}
                            ></i>
                          </li>
                        </ul>
                      </div>
                    )}
                    {showConfirmPassword && (
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="bi bi-lock"></i>
                        </span>
                        <input
                          className={
                            invalid ? "invalid form-control" : "form-control"
                          }
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPass}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                    )}
                    {!passwordsMatch && (
                      <p className="text-danger">Passwords do not match</p>
                    )}
                    {invalid && (
                      <p className="inv ps">Invalid email or password</p>
                    )}
                    {emailExistsError && (
                      <p className="inv ps">
                        {" "}
                        This email is already registered
                      </p>
                    )}
                    {inactive && (
                      <p className="inv ps" style={{ fontSize: "0.8rem" }}>
                        your subscription is inactive
                      </p>
                    )}
                    {activeButton !== "createaccount" && (
                      <button
                        type="button"
                        onClick={resetPasswordState}
                        className="m-0 p-0 col-12 text-start"
                        style={{
                          color: bright,
                          backgroundColor: "#0000",
                          borderWidth: 0,
                          fontWeight: "bold",
                        }}
                      >
                        Forgot Password?
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn loggin-btn"
                      disabled={
                        (activeButton === "createaccount" &&
                          (!strengthRules.hasLowerCase ||
                            !strengthRules.hasNumber ||
                            !strengthRules.hasSpecialChar ||
                            !strengthRules.hasUpperCase ||
                            !strengthRules.minLength ||
                            confirmPass === "" ||
                            passSignUp !== confirmPass)) ||
                        (activeButton === "signin" && invalid)
                      }
                    >
                      {activeButton === "createaccount"
                        ? "Register in Muze Art"
                        : "Sign in with Muze Art"}
                    </button>
                  </>
                )}
              </form>
              {loading && (
                <div className="d-flex justify-content-center">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
          {/* success modal */}
          {showSuccessModal && (
            <ModalSignUp
              show={showSuccessModal}
              onHide={() => setShowSuccessModal(false)}
              name="Muze Art"
              description="Please check your email for a sign up link to access your account."
              BackSignIn={BackSignIn}
            />
          )}
          {showSuccessFirstSubs && (
            <ModalStyle
              show={showSuccessFirstSubs}
              onHide={() => setShowSuccessFirstSubs(false)}
              name="Success"
              description="Congrats! Your subscription was successfull!"
              onStartCreating={goToDashboard}
              buttonLabel="Start Creating"
            />
          )}

          {passwordSuccess && (
            <ModalSignUp
              show={passwordSuccess}
              onHide={() => setPasswordSuccess(false)}
              name="Muze Art"
              description="Please check your email for a password reset link."
              BackSignIn={BackSignIn}
            />
          )}

          {showErrorMailVerification && (
            <ModalSignUp
              show={showErrorMailVerification}
              onHide={() => setShowErrorMailVerification(false)}
              name="Muze Art"
              description="An error occurred. Please try again later."
              BackSignIn={BackSignIn}
            />
          )}

          {showErrorManyRequests && (
            <ModalSignUp
              show={showErrorManyRequests}
              onHide={() => setShowErrorManyRequests(false)}
              name="Muze Art"
              description="Too many login attempts. Please wait a moment and try again."
              BackSignIn={BackSignIn}
            />
          )}

          {showErrorModal && (
            <ModalResendEmail
              show={showErrorModal}
              onHide={() => setShowErrorModal(false)}
              name="Muze Art"
              description="Your email hasn't been verified yet. Please click on Resend button to receive in your inbox the link to verify your email account."
              sendVerification={reSendVerificationEmail}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
export default Login;
