import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "wavesurfer.js/dist/plugins/timeline.js";
import Region from "wavesurfer.js/dist/plugins/regions.js";
import { MAX_AUDIO_LENGTH } from "../../store/audioAssetReducer";
import useAudioTrim from "../AudioTrim/useAudioTrim";
import { start } from "repl";

const MAX = MAX_AUDIO_LENGTH;

interface TrimPlayerProps {
  url: string;
  active: boolean;
  index?: number;
}

export default function TrimPlayerPrompts(props: TrimPlayerProps) {
  const { url, active, index } = props;
  const containerRef = useRef(null);
  const wavesurferRef = useRef<any>(null);
  const selectedRegionRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRegionManipulating, setIsRegionManipulating] = useState(false);

  const { delta } = useAudioTrim();
  const [region, setRegion] = useState<{ start: number; end: number }>();
  const [startEnd, setStartEnd] = useState<{ start: number; end: number }>({
    start: 0,
    end: MAX,
  });

  const [isPlayingState, setIsPlayingState] = useState(false);

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 100,
    waveColor: "#515151",
    progressColor: "#515151",
    dragToSeek: true,
    duration: delta.end - delta.start,
    barWidth: 2,
    cursorWidth: 2,
    cursorColor: "white",
    barGap: 3,
    url: url,

    // Remove the Timeline plugin to hide the timeline markings
    plugins: useMemo(() => [], []),
  });

  useEffect(() => {
    if (wavesurfer) {
      wavesurferRef.current = wavesurfer;

      wavesurfer.on("ready", () => {
        const initialStartEnd = {
          start: delta.start,
          end: delta.end,
        };

        setStartEnd({
          start: 0,
          end: initialStartEnd.end - initialStartEnd.start,
        });

        const regionsPlugin = wavesurfer.registerPlugin(Region.create());
        let selectedRegion = selectedRegionRef.current;

        if (!selectedRegion) {
          selectedRegion = regionsPlugin.addRegion({
            id: "selected",
            start: initialStartEnd.start,
            end: initialStartEnd.end,
            color: "rgba(220, 154, 154, .4)",
            drag: true,
            resize: true,
          });
          selectedRegionRef.current = selectedRegion;
        } else {
          selectedRegion.on("update-end", () => {
            selectedRegion.start = initialStartEnd.start;
            selectedRegion.end = initialStartEnd.end;
          });
        }

        selectedRegion.on("update-end", () => {
          setIsRegionManipulating(true); // Indica que a região está sendo manipulada
          let regionStart = selectedRegion.start;
          let regionEnd = selectedRegion.end;

          // Limita o final da região ao intervalo entre delta.start e delta.end
          regionEnd = Math.min(regionEnd, delta.end);
          regionEnd = Math.max(regionEnd, 0);

          selectedRegion.end = regionEnd;

          // Se o final da região for maior que startEnd.end, ajusta para startEnd.end
          if (regionEnd > startEnd.end) {
            selectedRegion.end = startEnd.end;
          }

          // if (regionStart < delta.start) {
          //   selectedRegion.start = delta.start;
          // }

          setStartEnd({
            start: 0,
            end: regionEnd - delta.start,
          });
        });

        selectedRegion.on("update", () => {
          setIsRegionManipulating(true); // Indica que a região está sendo manipulada
          let regionStart = selectedRegion.start;
          let regionEnd = selectedRegion.end;

          // Limita o final da região ao intervalo entre delta.start e delta.end
          regionEnd = Math.min(regionEnd, delta.end);
          regionEnd = Math.max(regionEnd, delta.start);

          selectedRegion.end = regionEnd;

          // Se o final da região for maior que startEnd.end, ajusta para startEnd.end
          if (regionEnd > startEnd.end) {
            selectedRegion.end = startEnd.end;
          }

          if (regionStart < delta.start || regionStart > delta.start) {
            selectedRegion.start = delta.start;
          }

          setStartEnd({
            start: 0,
            end: regionEnd - delta.start,
          });
        });

        setIsLoading(false); // Set loading state to false when wavesurfer is ready
      });
    }
  }, [wavesurfer, delta]);

  useEffect(() => {
    const initialStartEnd = {
      start: delta.start,
      end: delta.end,
    };

    if (
      (region && region.start && delta.start !== region.start) ||
      (region && region.start && delta.end !== region.end)
    ) {
      setRegion({
        start: delta.start,
        end: delta.end,
      });
      if (selectedRegionRef.current) {
        selectedRegionRef.current.start = initialStartEnd.start;
        selectedRegionRef.current.end = initialStartEnd.end;
        selectedRegionRef.current.update = true;
      }
    }
  }, [delta]);

  useEffect(() => {
    const initialStartEnd = {
      start: delta.start,
      end: delta.end,
    };
    if (selectedRegionRef.current) {
      wavesurferRef.current.pause();
      setIsPlayingState(false);
      selectedRegionRef.current.start = initialStartEnd.start;
      selectedRegionRef.current.end = initialStartEnd.end;
      selectedRegionRef.current._onUpdate(
        selectedRegionRef.current.update,
        "update"
      );
    }
  }, [active]);

  const onPlayPause = useCallback(() => {
    if (wavesurfer) {
      const { start, end } = delta;
      const duration = wavesurfer.getDuration();
      const maxEnd = end - start; // Calcula o valor máximo de end

      if (isPlayingState) {
        wavesurfer.playPause();
        setIsPlayingState(false);
      } else {
        wavesurfer.seekTo(start / duration);
        wavesurfer.play();
        setIsPlayingState(true);

        const playInterval = setInterval(() => {
          const currentTime = wavesurfer.getCurrentTime();
          // Ajusta currentTime para garantir que esteja dentro do intervalo permitido
          const adjustedCurrentTime = Math.min(currentTime - start, maxEnd);

          if (adjustedCurrentTime >= maxEnd) {
            wavesurfer.pause();
            setIsPlayingState(false);
            clearInterval(playInterval);
          } else {
            setStartEnd((prevStartEnd) => ({
              ...prevStartEnd,
              start: adjustedCurrentTime,
            }));
          }
        }, 100);
      }
    }
  }, [wavesurfer, isPlayingState, delta]);

  return (
    <div className={`${active ? "d-flex" : "d-none"} flex-column text-center`}>
      <div className="d-flex">
        {!isLoading && (
          <button onClick={onPlayPause} className="play-pause-btn">
            {isPlayingState ? (
              <i className="bi bi-pause"></i>
            ) : (
              <i className="bi bi-play"></i>
            )}
          </button>
        )}
        <div ref={containerRef} className="wavesurfer-container" />
      </div>
      {isLoading && <div style={{ height: "3rem" }}>Loading...</div>}
      {!isLoading && <Time {...{ startEnd }} />}
    </div>
  );
}

function Time(props: any) {
  const { startEnd } = props;

  let t = "00:00";
  const tt = formatSecondsAsTime(startEnd.end);

  if (startEnd.start > 0) {
    t = formatSecondsAsTime(startEnd.start);
  }

  return (
    <p className="small text-start text-muted p-0 m-0 mt-1 d-flex justify-content-between">
      <span>{t}</span>
      <span>{tt}</span>
    </p>
  );
}

function formatSecondsAsTime(secs: number) {
  let hr = Math.floor(secs / 3600);
  let min: any = Math.floor((secs - hr * 3600) / 60);
  let sec: any = Math.floor(secs - hr * 3600 - min * 60);

  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return min + ":" + sec;
}
