import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AnimationModeSelector from "../GenerateVideo/ChooseStyle/AnimationModeSelector";
import AspectRatioSelector from "../GenerateVideo/ChooseStyle/AspectRatioSelector";
import BoomerangSelector from "../GenerateVideo/ChooseStyle/BoomerangSelector";
import Name from "../GenerateVideo/ChooseStyle/Name";
import "./styles.css";

import { Toaster } from "react-hot-toast";
import useAudioTrim from "../AudioTrim/useAudioTrim";

// @ts-ignore
import Mirt from "react-mirt";
import "react-mirt/dist/css/react-mirt.css";
import { useAuth } from "../../AuthProvider";
import {
  AudioAsset,
  useReloadAudioDefaultQuery,
  useReloadAudioQuery,
} from "../../API";
import { useAudioAsset, useStartingFrame } from "../../store/hooks";
import { APIClient } from "../../utils/services";
import PopUp from "../SharedComponents/PopUp";
import {
  setNoAudio,
  setSpotify,
  setTempAudio,
} from "../../store/generateVideoReducer";
import { useDispatch } from "react-redux";
import usePaginator from "../Paginator/usePaginator";
import Paginator from "../Paginator";
import { useCustomEventListener } from "react-custom-events";
import TrimPlayer from "../GenerateVideo/AudioPage/SelectStartAndStop/TrimPlayer";
import { Toast } from "react-bootstrap";

const TITLE = "Audio";
const SUBTITLE = "Upload your audio";
const DESCRIPTION = "You can upload mp3 files";
const UPLOAD = "Upload";
const CFY_LYBRARY = "Choose from your library";
const MESSAGE = "Uploaded audios will appears here.";

export default function AudioPage() {
  const [isUpload, setIsUpload] = useState(false);
  const [isLibrary, setIsLibrary] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [isNoAudioSelected, setIsNoAudioSelected] = useState(false);
  const openLibrary = () => +setIsLibrary(true) + +setIsUpload(false);
  const openUpload = () => setIsUpload(true);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const l = useLocation();

  const hidePrev = !l.pathname.split("/").includes("image-to-video");

  useEffect(() => {
    if (hidePrev) setIsLibrary(false);
  }, [hidePrev]);

  const { copyInfo } = l.state || {};

  useEffect(() => {
    if (copyInfo) {
      setIsLibrary(true);
    }
  }, [copyInfo]);

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps rounded m-2 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div
            className="col-12 d-flex flex-column p-1 gap-2"
            style={{ backgroundColor: "#343333" }}
          >
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
          </div>
          <div
            className={`d-flex flex-column align-items-center flex-grow-1 ${
              window.innerWidth < 1600 ? "p-3" : "p-5"
            } justify-content-center col-${
              window.innerWidth < 1600 ? "12" : "12"
            }`}
          >
            {isLibrary &&
              window.innerWidth < 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev {...{ hidePrev }} next="../audio-reactivity" />
              )}
            <header
              className={`p-${
                window.innerWidth < 1600 ? "3" : "4"
              } d-flex text-center mb-${window.innerWidth < 1600 ? "3" : "4"}`}
            >
              <h1 className="fw-bold">🎶 {TITLE}</h1>
            </header>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex justify-content-center gap-3">
                <div
                  onClick={() => +setIsLibrary(false) + +setIsUpload(false)}
                  style={{
                    borderBottom: !isLibrary ? "2px #FF30C4 solid" : "",
                  }}
                >
                  {UPLOAD}
                </div>
                <div
                  onClick={openLibrary}
                  style={{ borderBottom: isLibrary ? "2px #FF30C4 solid" : "" }}
                >
                  {CFY_LYBRARY}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center col-12">
              {!isLibrary && <AudioTrim />}
              {isLibrary && (
                <ChooseFromYourLibrary
                  setIsSelected={setIsSelected}
                  setIsNoAudioSelected={setIsNoAudioSelected}
                  isNoAudioSelected={isNoAudioSelected}
                />
              )}
            </div>
            {isLibrary &&
              window.innerWidth > 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev {...{ hidePrev }} next="../audio-reactivity" />
              )}
          </div>
        </div>
      ) : (
        <div className="ps rounded m-3 d-flex flex-grow-1 flex-column align-items-center overflow-hidden">
          <div className="border-bottom col-12 d-flex p-3 gap-5">
            <Name />
            <AnimationModeSelector />
            <AspectRatioSelector />
            <BoomerangSelector />
          </div>
          <div
            className={`d-flex flex-column align-items-center flex-grow-1 ${
              window.innerWidth < 1600 ? "p-3" : "p-5"
            } justify-content-center col-${
              window.innerWidth < 1600 ? "10" : "12"
            }`}
          >
            {isLibrary &&
              window.innerWidth < 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev {...{ hidePrev }} next="../audio-reactivity" />
              )}
            <header
              className={`p-${
                window.innerWidth < 1600 ? "1" : "2"
              } d-flex text-center mb-${window.innerWidth < 1600 ? "1" : "2"}`}
            >
              <h1 className="fw-bold">{TITLE}</h1>
            </header>
            <div className="d-flex flex-column overflow-auto gap-3">
              <div className="d-flex justify-content-center gap-5">
                <div
                  onClick={() => +setIsLibrary(false) + +setIsUpload(false)}
                  style={{
                    borderBottom: !isLibrary ? "2px #FF30C4 solid" : "",
                  }}
                >
                  {UPLOAD}
                </div>
                <div
                  onClick={openLibrary}
                  style={{ borderBottom: isLibrary ? "2px #FF30C4 solid" : "" }}
                >
                  {CFY_LYBRARY}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center col-12">
              {!isLibrary && <AudioTrim />}
              {isLibrary && (
                <ChooseFromYourLibrary
                  setIsSelected={setIsSelected}
                  setIsNoAudioSelected={setIsNoAudioSelected}
                  isNoAudioSelected={isNoAudioSelected}
                />
              )}
            </div>
            {isLibrary &&
              window.innerWidth > 1600 &&
              typeof window.innerWidth === "number" && (
                <NextPrev {...{ hidePrev }} next="../audio-reactivity" />
              )}
          </div>
        </div>
      )}
    </>
  );
}

function AdvancedToastAudioValidation(props: any) {
  const { showAdvancedToast, onClose } = props;

  return (
    <Toast show={showAdvancedToast} onClose={onClose}>
      <Toast.Header>
        <i
          className="bi bi-info-circle"
          style={{ fontSize: "1rem", marginRight: "8px" }}
        />
        <strong className="me-auto">Muze Art</strong>
      </Toast.Header>
      <Toast.Body>
        Audio duration exceeds the 4 minutes limit. Try upload another audio
        again
      </Toast.Body>
    </Toast>
  );
}

function AudioTrim() {
  const {
    send,
    handleChange,
    handleDragOver,
    handleDrop,
    setShowToastLimitDurationAudio,
    showToastLimitDurationAudio,
    delta,
    isLoading,
    file,
    isLoadingProgress,
    audioKey,
  } = useAudioTrim();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const inp = {
    id: "audioFile",
    ref: inputFile,
    style: { display: "none" },
    accept: ".mp3, .wav",
  };

  return (
    <div
      className="d-flex flex-column p-1 align-items-center justify-content-between col-12"
      style={{ height: "20rem", margin: "2rem" }}
    >
      <div>
        {" "}
        {showToastLimitDurationAudio && (
          <AdvancedToastAudioValidation
            onClose={() => setShowToastLimitDurationAudio(false)}
          />
        )}
      </div>
      <Toaster />
      {!isLoading && <h1 className="ps">Cut MP3</h1>}
      {/* {!isLoading && (
        <div
          className="upload-btn"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          {...{ onClick }}
        >
          <input type="file" {...inp} onChange={handleChange} />
          <i className="bi bi-upload" />
          <p>{SUBTITLE}</p>
        </div>
      )} */}
      <div className="d-flex justify-content-center col-12">
        {!isLoadingProgress && isLoading && (
          <div
            className="upload-btn"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            {...{ onClick }}
          >
            <input type="file" {...inp} onChange={handleChange} />
            <i className="bi bi-upload" />
            <p>{SUBTITLE}</p>
            <p>{DESCRIPTION}</p>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="col-12">
          <TrimPlayer
            key={audioKey}
            url={file.url ? file.url : ""}
            active={!isLoading}
            index={0}
          />
        </div>
      )}
      {!isLoading && (
        <div className="d-flex flex-column gap-4 p-2">
          <div className="d-flex gap-3 justify-content-between">
            <button
              className="upload-audio"
              onClick={() => send(delta.start, delta.end)}
              disabled={isLoading}
            >
              Upload Audio
            </button>
          </div>
        </div>
      )}
      <div style={{ width: "100%" }}>{isLoadingProgress && <Spinner />}</div>
    </div>
  );
}

function UploadBtn(props: any) {
  const { onClick } = props;
  return (
    <div className="upload-btn" {...{ onClick }}>
      <i className="bi bi-upload" />
      <p>{SUBTITLE}</p>
      <p>{DESCRIPTION}</p>
    </div>
  );
}

function useMusicLibrary() {
  const [musics, setMusics] = useState<any[]>([]);
  const [arrayFSort, setArrayFSort] = useState<any[]>([]);
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const page = actual;
  const perPage = 10;

  const { isLoading, isError, data, refetch } = useReloadAudioQuery({
    ownerId,
    page,
    perPage,
  });

  const {
    isLoading: isLoadingDefault,
    isSuccess: isSuccessDefault,
    data: dataDefault,
    refetch: refetchDefault,
  } = useReloadAudioDefaultQuery({
    page,
    perPage,
  });

  const { setMusic, selectedMusic } = useAudioAsset();
  const [src, setSrc] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteAssetId, setDeleteAssetId] = useState("");

  const [search] = useSearchParams();
  const query = search.get("query") || "";

  const deleteAudio = (id: string) => {
    setDeleteAssetId(id);
    setShowModal(true);
  };

  const modal = {
    title: "Delete Audio",
    text: "Are you sure you want to delete this audio?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      client.delete_audio_resource(ownerId, deleteAssetId, () => refetch());
      setDeleteAssetId("");
      setShowModal(false);
    },
    onClose: () => {
      setDeleteAssetId("");
      setShowModal(false);
    },
  };

  const getAudios = () => {
    try {
      refetch().then((result: any) => {
        const { isSuccess, data } = result;

        if (isSuccess && data && data.results && data.results.items) {
          setTotal(data != null ? data.results.total_items : 0);
          const sortedArray = [...data.results.items];
          sortedArray.sort((a, b) => {
            const dateA = new Date(a.last_update).getTime();
            const dateB = new Date(b.last_update).getTime();
            return dateB - dateA;
          });

          setArrayFSort(sortedArray);

          const sortedMusics = sortedArray
            .map((...v: any) => formatMusic(setSrc, deleteAudio, ...v))
            .filter((m: any) => filterMusic(m, query));

          setMusics(sortedMusics);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useCustomEventListener("onAudioUpload", () => {
    getAudios();
  });

  useEffect(() => {
    getAudios();
  }, [data, query, actual, refetch]);

  useEffect(() => {
    setTotal(data != null ? data.results.total_items : 0);
  }, [setTotal, refetch]);

  return {
    refetch,
    data,
    getAudios,
    setTotal,
    musics,
    setMusic,
    selectedMusic,
    isLoading,
    isError,
    modal,
  };
}

function ChooseFromYourLibrary({
  setIsSelected,
  setIsNoAudioSelected,
  isNoAudioSelected,
}: {
  setIsSelected: any;
  setIsNoAudioSelected: any;
  isNoAudioSelected: any;
}) {
  const [arrayFSort, setArrayFSort] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [src, setSrc] = useState({});
  const [deleteAssetId, setDeleteAssetId] = useState("");
  const [search] = useSearchParams();
  const query = search.get("query") || "";
  const [musics, setMusics] = useState<any[]>([]);
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;

  const { currentUser } = useAuth();
  const ownerId = `${currentUser?.email}`;

  const page = actual;
  const perPage = 10;

  const { isLoading, isSuccess, isError, data, refetch } = useReloadAudioQuery({
    ownerId,
    page,
    perPage,
  });

  const {
    isLoading: isLoadingDefault,
    isSuccess: isSuccessDefault,
    data: dataDefault,
    refetch: refetchDefault,
  } = useReloadAudioDefaultQuery({
    page,
    perPage,
  });

  const FixedMusicCard = () => {
    const dispatch = useDispatch();
    const { setMusic } = useAudioAsset();

    const handleClick = () => {
      dispatch(setNoAudio(true));
      dispatch(setSpotify(false));
      dispatch(setTempAudio({ name: "", key: "", delete_source: false }));
      setMusic({} as AudioAsset);
      setIsNoAudioSelected(true);
    };

    return (
      <div
        className={`rounded d-flex flex-column align-items-center music-card ${
          isNoAudioSelected && "music-card-selected"
        }`}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <div className="border-bottom d-flex align-items-center justify-content-between ps-3 gap-3 col-12">
          <div className="p-2">No audio option</div>
        </div>
        <i className="bi bi-slash-circle" style={{ fontSize: "6rem" }} />
        <div className="text-muted mb-3 music-card-name">Select</div>
      </div>
    );
  };

  const getAudios = () => {
    try {
      Promise.all([refetch(), refetchDefault()]).then((results: any) => {
        const result = results[0];
        const defaultResult = results[1];

        const { isSuccess, data } = result || {};
        const { isSuccess: isSuccessDefault, data: dataDefault } =
          defaultResult || {};

        if (
          (isSuccess || isSuccessDefault) &&
          data != null &&
          dataDefault != null
        ) {
          const dataItems = data.results?.items || [];
          const defaultItems = dataDefault.results?.items || [];

          const combinedArray = [...dataItems, ...defaultItems];

          if (combinedArray?.length > 0) {
            const sortedArray = combinedArray.sort((a, b) => {
              const dateA = new Date(a.last_update).getTime();
              const dateB = new Date(b.last_update).getTime();
              return dateB - dateA;
            });

            setArrayFSort(sortedArray);

            const sortedMusics = sortedArray
              .map((...v: any) => formatMusic(setSrc, deleteAudio, ...v))
              .filter((m: any) => filterMusic(m, query));

            setMusics(sortedMusics);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAudio = async (id: string) => {
    setDeleteAssetId(id);
    setShowModal(true);
  };

  const modal = {
    title: "Delete Audio",
    text: "Are you sure you want to delete this audio?",
    confirmText: "Confirm",
    shouldShow: showModal,
    onConfirm: async () => {
      const client = new APIClient();
      client.authenticate(
        undefined,
        undefined,
        process.env.REACT_APP_MUZE_API_KEY
      );
      await client.delete_audio_resource(ownerId, deleteAssetId, () =>
        refetch()
      );
      setDeleteAssetId("");
      setShowModal(false);
    },
    onClose: () => {
      setDeleteAssetId("");
      setShowModal(false);
    },
  };

  useCustomEventListener("onAudioUpload", () => {
    getAudios();
  });

  useEffect(() => {
    getAudios();
  }, [actual, refetch]);

  useEffect(() => {
    setTotal(data != null ? data.results.total_items : 0);
  }, [setTotal, refetch]);

  useEffect(() => {
    refetch();
  }, []);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div className="overflow-hidden">
          {!isLoading && !isError && (
            <>
              {/* <div className="ms-auto p-2 mb-auto">
                <Search />
              </div> */}
              <div className="d-flex rounded border flex-wrap justify-content-center overflow-auto p-3 gap-3">
                <FixedMusicCard />
                {Array.isArray(musics) &&
                  musics.length > 0 &&
                  musics.map((music: any) => (
                    <MusicCard
                      key={music.id}
                      {...music}
                      setIsSelected={setIsSelected}
                      setIsNoAudioSelected={setIsNoAudioSelected}
                      isNoAudioSelected={isNoAudioSelected}
                    />
                  ))}
              </div>
            </>
          )}
          <PopUp {...modal} />
          {isLoading && <Loading />}
          {isError && <Error />}
          {data?.results?.total_items && <Paginator {...paginator} />}
        </div>
      ) : (
        <div className="choose-from-library overflow-hidden">
          {!isLoading && !isError && (
            <>
              <div className="ms-auto p-1 mb-auto">
                <Search />
              </div>
              <div
                className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3"
                style={{ height: "700px" }}
              >
                <FixedMusicCard />
                {Array.isArray(musics) &&
                  musics.length > 0 &&
                  musics.map((music: any) => (
                    <MusicCard
                      key={music.id}
                      {...music}
                      setIsSelected={setIsSelected}
                      setIsNoAudioSelected={setIsNoAudioSelected}
                      isNoAudioSelected={isNoAudioSelected}
                    />
                  ))}
              </div>
            </>
          )}
          <PopUp {...modal} />
          {isLoading && <Loading />}
          {isError && <Error />}
          {data?.results?.total_items && <Paginator {...paginator} />}
        </div>
      )}
    </>
  );
}

function Loading() {
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      {!isMobileDevice() && <h3>Loading ...</h3>}
      {isMobileDevice() && <strong>Loading ...</strong>}
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}

function MusicCard(props: any) {
  const { setMusic, selectedMusic } = useMusicLibrary();
  const {
    date,
    name,
    deleteAudio,
    icon,
    id,
    status,
    asset,
    errorTimeOut,
    statusTimeOut,
    setIsSelected,
    setIsNoAudioSelected,
    isNoAudioSelected,
  } = props;

  const selected = isNoAudioSelected ? false : id === selectedMusic.asset_id;
  const dispatch = useDispatch();

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    if (selected && status === "DONE") {
      const timeoutId = setTimeout(() => {
        setMusic(asset);
        setIsSelected(true);
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [selected, status, asset, setMusic]);

  const onClick = () => {
    if (!selected && status === "DONE") {
      setMusic(asset);
      setIsSelected(true);
      setIsNoAudioSelected(false);
    } else {
      setMusic({} as AudioAsset);
      setIsSelected(false);
    }
    dispatch(setTempAudio({ name: "", key: "", delete_source: false }));
    dispatch(setSpotify(false));
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`rounded d-flex flex-column align-items-center music-card ${
            selected && "music-card-selected"
          }`}
          style={{
            width: "100%",
            marginBottom: "0.5rem",
            marginRight: "10px",
            maxWidth: "38%",
          }}
          onClick={onClick}
        >
          <div className="border-bottom d-flex align-items-center justify-content-between ps-3 gap-3 col-12">
            {/* <div>{date}</div> */}
            <i
              className="btn btn-dark bi bi-trash"
              onClick={(e) => {
                e.stopPropagation();
                deleteAudio(id);
              }}
            />
          </div>
          <i className={`bi bi-${icon}`} style={{ fontSize: "2rem" }} />
          <div
            className="text-muted mb-1 music-card-name"
            style={{ fontSize: "11px" }}
          >
            {name}
          </div>
          {/* <div style={{ fontSize: "0.65rem" }}>
            {errorTimeOut ? statusTimeOut : status}
          </div> */}
        </div>
      ) : (
        <div
          className={`rounded d-flex flex-column align-items-center music-card ${
            selected && "music-card-selected"
          }`}
          onClick={onClick}
        >
          <div className="border-bottom d-flex align-items-center justify-content-between ps-3 gap-3 col-12">
            <div>{date}</div>
            <i
              className="btn btn-dark bi bi-trash"
              onClick={(e) => {
                e.stopPropagation();
                deleteAudio(id);
              }}
            />
          </div>
          <i className={`bi bi-${icon}`} style={{ fontSize: "5rem" }} />
          <div className="text-muted mb-3 music-card-name">{name}</div>
          <div style={{ fontSize: "0.65rem" }}>
            {errorTimeOut ? statusTimeOut : status}
          </div>
        </div>
      )}
    </>
  );
}

function Search() {
  const [search, setSearch] = useSearchParams();
  const query = search.get("query") || "";

  return (
    <div
      className="d-flex gap-3 align-items-center"
      style={{ position: "relative" }}
    >
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => setSearch({ query: e.target.value })}
        value={query}
      />
      {query === "" ? (
        <i className="bi bi-search search-inner-btn" />
      ) : (
        <i className="bi bi-x search-inner-btn" onClick={() => setSearch("")} />
      )}
    </div>
  );
}

export function NextPrev(props: any) {
  const pausePath = `M12.404 2.364A2.364 2.364 0 0 0 10.041 0H6.134a2.364 2.364 0 0 0-2.363 2.364v27.273A2.364 2.364 0 0 0 6.134 32h3.907a2.364 2.364 0 0 0 2.363-2.363V2.364Zm15.826 0A2.364 2.364 0 0 0 25.866 0H21.96a2.364 2.364 0 0 0-2.364 2.364v27.273A2.364 2.364 0 0 0 21.96 32h3.906a2.364 2.364 0 0 0 2.364-2.363V2.364Z`;
  const pause = () =>
    document.querySelectorAll("path").forEach((i) => {
      if (i.getAttribute("d") === pausePath) {
        i.parentElement?.parentElement?.click();
      }
    });
  const { videoName } = useStartingFrame();
  const { hidePrev, next, fill, nextLabel, nextFunc, hideNext } = props;
  const n = useNavigate();
  const isNextAudioSelect = next === "../audio-reactivity";
  const isNextAudioReactivity = next === "../choose-art-style";
  const isNextArtStyle = next === "../prompts";

  const isVideoNameEmpty = videoName.trim() === "";
  const isVideoNameNonEnglish =
    !/^[a-zA-Z0-9\s!@#$%^&*()_+={}[\]:;<>,.?~\\/-]+$/.test(videoName);
  const isVideoNameSpecialCharacters = !/^[a-zA-Z0-9\s]*$/.test(videoName);

  const onClickPrev = () => {
    pause();
    n(-1);
  };

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const onClick =
    nextFunc ||
    (next
      ? () => {
          if (
            isVideoNameEmpty ||
            isVideoNameNonEnglish ||
            isVideoNameSpecialCharacters
          ) {
            return;
          }

          pause();
          n(next);
        }
      : () => console.warn("You should set 'next' for NextPrev component"));

  return (
    <div
      className={`d-flex flex-column ${
        window.innerWidth < 1600 ? "col-11" : "col-12"
      }`}
      style={{ marginTop: isMobileDevice() ? "8px" : undefined }}
    >
      <div
        className={`d-flex ${
          isNextAudioSelect || isNextAudioReactivity || isNextArtStyle
            ? ""
            : "flex-column"
        } ms-auto gap-3 ${fill && "col-12"}`}
      >
        {!hidePrev && (
          <div
            className="btn btn-dark"
            onClick={onClickPrev}
            style={{ fontSize: isMobileDevice() ? "small" : undefined }}
          >
            Previous Step
          </div>
        )}
        {!hideNext && (
          <div
            className={`btn ${
              isVideoNameEmpty ||
              isVideoNameNonEnglish ||
              isVideoNameSpecialCharacters
                ? "btn-danger"
                : "btn-success"
            }`}
            {...{ onClick }}
            style={{ fontSize: isMobileDevice() ? "small" : undefined }}
          >
            {isVideoNameEmpty
              ? "Set 'Video Name' first"
              : isVideoNameNonEnglish
              ? "'Video Name' contains non-english characters"
              : isVideoNameSpecialCharacters
              ? "'Video Name' contains special characters"
              : nextLabel || "Next Step"}
          </div>
        )}
      </div>
    </div>
  );
}

function Spinner() {
  return (
    <div
      style={{ height: "100%" }}
      className="d-flex justify-content-center align-items-center"
    >
      <div
        className="spinner-border"
        style={{ width: "5rem", height: "5rem" }}
        role="status"
      />
    </div>
  );
}

function formatMusic(setSrc: Function, deleteAudio: Function, ...a: any) {
  const [i, k, l] = a;
  const ok = i.AUDIO_status === "DONE";

  const d = {
    asset: { ...i },
    id: i.asset_id,
    name: i.name.split(".mp3")[0],
    status: i.AUDIO_status,
    date: new Date(i.timestamp).toLocaleDateString(),
    icon: ok
      ? "file-earmark-music"
      : i.AUDIO_status === "ERROR-States.Timeout"
      ? "exclamation-triangle"
      : "clock-history",
    deleteAudio,
    statusTimeOut: "TIMEOUT ERROR",
    errorTimeOut: i.AUDIO_status === "ERROR-States.Timeout",
  };
  const set = () => setSrc({ ...d, src: i.http_link, close: () => setSrc({}) });
  return {
    ...d,
    className: ok ? "btn-dark" : "",
    isLast: k === l.length - 1 ? "me-auto" : "",
    onClick: ok ? set : () => {},
  };
}

function filterMusic(
  { name, date }: { name: string; date: string },
  query: string
) {
  return (
    query === "" ||
    name.toLowerCase().includes(query.toLowerCase()) ||
    date.includes(query)
  );
}
