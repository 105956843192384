import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/services";
import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useAuth } from "../../AuthProvider";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import { checkSimultaneousGenerationRestyle } from "../../utils/utils";
import { commonImageTypes, validFileType } from "../CreateAvatar/utils";
import { NextPrev } from "../AudioPage";
import { useCustomEventListener } from "react-custom-events";
import axios from "axios";
import usePaginator from "../Paginator/usePaginator";
import { useStartingFrame } from "../../store/hooks";
import Paginator from "../Paginator";
import { useGetImageAssetsQuery } from "../../API";
import ModalPurchase from "../GenerateVideo/ModalPurchase";
import ModalRemainCredits from "../GenerateVideo/ModalRemainCredits";
import { setSubject } from "../../store/promptsReducer";
import Select, { MultiValue } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ControlNetModel {
  value: string;
  label: JSX.Element;
  description: string;
}

interface ControlNetModelProcess {
  value: string;
  label: React.ReactElement;
  description: string;
}

const TITLE = "Video Settings";
const SUBTITLE_IMAGE =
  "Optional: upload a reference image in the style that you want your video to be";
const HEADER = "Describe how you want your video to look";
const UPLOAD = "Upload";
const CFY_LYBRARY = "Choose from your library";
const MESSAGE = "Uploaded images will appears here.";
const IPADAPTER_REF_IMAGE = "Choose ipadapter image";
const controlnets_models: ControlNetModel[] = [
  {
    value: "canny",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>Canny</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "Canny is a general-purpose, old-school edge detector. It extracts the outlines of an image. It is useful for retaining the composition of the original image.",
  },
  {
    value: "lineart",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>Lineart</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "Line Art renders the outline of an image. It attempts to convert it to a simple drawing.",
  },
  {
    value: "softedge",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>Softedge</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "Creates smooth lines around objects, especially useful for recoloring and stylizing, using soft-edge detection.",
  },
  {
    value: "mediapipe_face",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>Mediapipe Face</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "Used to replace or re-draw the subject's facial pose and expression.",
  },
  {
    value: "depth",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>Depth</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "Replace or re-draw the subject (or parts of an image), based on greyscale depth maps.",
  },
  {
    value: "openpose",
    label: (
      <>
        <span style={{ paddingRight: "5px" }}>OpenPose</span>
        <FontAwesomeIcon icon={faInfoCircle} />
      </>
    ),
    description:
      "OpenPose will detect a human pose and apply it to a subject in your image. It creates a 'skeleton' with a head, trunk, and limbs, and can even include hands (with fingers) and facial orientation. Multiple OpenPose Skeletons can be combined to create dynamic crowd scenes.",
  },
];

const controlnets_preprocesses: ControlNetModelProcess[] = [
  { value: "canny", label: <span>Canny</span>, description: "Edge detector." },
  {
    value: "lineart",
    label: <span>Line Art</span>,
    description: "Converts to simple drawing.",
  },
  { value: "hed", label: <span>HED</span>, description: "HED description." },
  {
    value: "face",
    label: <span>Face</span>,
    description: "Facial processing.",
  },
  { value: "zoe", label: <span>Zoe</span>, description: "Zoe description." },
  {
    value: "openpose",
    label: <span>OpenPose</span>,
    description: "Pose detection.",
  },
];

const imageBucketBaseURL = process.env.REACT_APP_IMAGES_BUCKET_URL;
const {
  REACT_APP_MUZE_API_HOST,
  REACT_APP_IMAGES_CDN_URL,
  REACT_APP_IPADAPTER_IMAGES_CDN,
} = process.env;
const service = new APIClient();

export default function RestylePage() {
  const [controlnetsModels, setControlNetsModels] = useState<ControlNetModel[]>(
    [
      controlnets_models.find((model) => model.value === "mediapipe_face")!,
      controlnets_models.find((model) => model.value === "softedge")!,
      controlnets_models.find((model) => model.value === "lineart")!,
    ]
  );

  const [controlnetsPreProcesses, setControlnetsPreProcesses] = useState<
    ControlNetModelProcess[]
  >([
    controlnets_preprocesses.find((preprocess) => preprocess.value === "face")!,
    controlnets_preprocesses.find((preprocess) => preprocess.value === "hed")!,
    controlnets_preprocesses.find(
      (preprocess) => preprocess.value === "lineart"
    )!,
  ]);

  const [tooltip, setTooltip] = useState<{
    description: string;
    left: number;
    top: number;
  } | null>(null);

  const [controlnetScales, setControlnetScales] = useState<number[]>([
    0.9, 0.8, 0.8,
  ]);
  const [ipadapterScale, setIpadapterScale] = useState<number>(0.7);
  const [isUpload, setIsUpload] = useState(true);
  const [isIpadapterImages, setIpadapterImages] = useState(false);
  const [isLoadingProgress, setIsLoadingProgress] = useState(false);
  const [noImages, setNoImages] = useState(false);
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const location = useLocation();
  const [inputValue, setInputValue] = useState<string>("");
  const [positivePrompt, setPositivePrompt] = useState<string>("");
  const [subjectMatter, setSubjectMatter] = useState("");
  const [ipadapterReferenceimage, setIpadapterReferenceimage] =
    useState("ip_image_9.png");
  const { videoInfo } = location.state || {};
  const [url, setUrl] = useState<any | undefined>(undefined);
  const [videoName, setVideoName] = useState<any | undefined>(undefined);
  const navigate = useNavigate();
  const [frameWidth, setFrameWidth] = useState(640);
  const [frameHeight, setFrameHeight] = useState(640);
  const [isWatermark, setIsWatermark] = useState("False");
  const [scalingFactor, setScalingFactor] = useState(1.5);
  const [outputType, setOutputType] = useState("entire");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [showModalRemain, setShowModalRemain] = useState(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState(true);

  const handleUpgradePlan = () => {
    navigate("/app/purchase");
  };

  const backToManagePlan = () => {
    navigate("/app/settings/billing");
  };

  const handleScaleChange = (index: number, value: number) => {
    const newScales = [...controlnetScales];
    newScales[index] = value;
    setControlnetScales(newScales);

    const filledScales = newScales.filter(
      (scale) => scale !== undefined && scale !== null
    );
    if (filledScales.length < 3) {
      toast.error(
        "Please select values for at least three control net scales."
      );
      return;
    }
  };

  const handleModelChange = (selectedOptions: any) => {
    if (selectedOptions.length > 3) {
      toast.error("You cannot select more than 3 options");
      return;
    }

    setControlNetsModels(selectedOptions || []);

    setIsPlayerVisible(selectedOptions.length === 0);
  };

  const handleModelProcessChange = (selectedOptions: any) => {
    if (selectedOptions.length > 3) {
      toast.error("You cannot select more than 3 options");
      return;
    }

    setControlnetsPreProcesses(selectedOptions || []);

    setIsPlayerVisible(selectedOptions.length === 0);
  };

  const customStyles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: "black",
      borderColor: state.isFocused ? "grey" : "",
      color: "white",
      "&:hover": {
        borderColor: "grey",
      },
    }),
    option: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? "grey" : "black",
      color: "white",
    }),
    multiValue: (baseStyles: any) => ({
      ...baseStyles,
      backgroundColor: "grey",
      color: "white",
    }),
    multiValueLabel: (baseStyles: any) => ({
      ...baseStyles,
      color: "white",
    }),
    multiValueRemove: (baseStyles: any) => ({
      ...baseStyles,
      color: "white",
      ":hover": {
        backgroundColor: "darkred",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 1600;

  const handlePositivePromptChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  useEffect(() => {
    if (inputValue === "") {
      setPositivePrompt(
        "{subject_matter}, Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K"
      );
    } else {
      setPositivePrompt(`{subject_matter}, ${inputValue}`);
    }
  }, [inputValue]);

  const handleSubjectMatterChange = (e: any) => {
    setSubjectMatter(e.target.value);
  };

  const handleOutputTypeChange = (e: any) => {
    setOutputType(e.target.value);
  };

  useEffect(() => {
    if (videoInfo) {
      console.log('video information', videoInfo)
      setUrl(videoInfo.url);
      setVideoName(videoInfo.name);

      if (videoInfo.width === videoInfo.height) {
        setFrameWidth(640);
        setFrameHeight(640);
        setScalingFactor(1.25);
      } else if (videoInfo.width > videoInfo.height) {
        setFrameWidth(Math.min(videoInfo.width, 768));
        setFrameHeight(Math.min(videoInfo.height, 480));
      } else if (videoInfo.width < videoInfo.height) {
        setFrameWidth(Math.min(videoInfo.width, 480));
        setFrameHeight(Math.min(videoInfo.height, 768));
      }
    }
  }, [videoInfo]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await service.getUserByEmail(ownerId);
        const result = res?.account_type;
        if (result === "free" || ownerId === "demo@mymuze.art") {
          setIsWatermark("True");
        } else {
          setIsWatermark("False");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [ownerId]);

  async function restyleGenerate(
    owner_id: string,
    video_name: string,
    is_watermark: string,
    positivePrompt: string = "{subject_matter}, Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K",
    subjectMatter: string,
    outputType: string,
    frameWidth: number,
    frameHeight: number,
    scalingFactor: number = 1.5,
    ipadapterReferenceimage: string = "ip_image_9.png",
    controlnetsModels: string[] = ["mediapipe_face", "softedge", "lineart"],
    controlnetsPreProcesses: string[] = ["face", "hed", "lineart"],
    controlnetScales: number[] = [0.9, 0.8, 0.8],
    ipadapterScale: number = 0.7
  ) {
    const service = new APIClient();

    if (controlnetsModels.length === 0) {
      setControlNetsModels([
        controlnets_models.find((model) => model.value === "mediapipe_face")!,
        controlnets_models.find((model) => model.value === "softedge")!,
        controlnets_models.find((model) => model.value === "lineart")!,
      ]);
    }

    if (controlnetsPreProcesses.length === 0) {
      setControlnetsPreProcesses([
        controlnets_preprocesses.find(
          (preprocess) => preprocess.value === "face"
        )!,
        controlnets_preprocesses.find(
          (preprocess) => preprocess.value === "hed"
        )!,
        controlnets_preprocesses.find(
          (preprocess) => preprocess.value === "lineart"
        )!,
      ]);
    }

    if (controlnetsModels.length < 3) {
      if (controlnetsModels.length > 0) {
        toast.error("Please select at least 3 models.", { duration: 4000 });
        return false;
      }
    }

    if (controlnetsPreProcesses.length < 3) {
      if (controlnetsPreProcesses.length > 0) {
        toast.error("Please select at least 3 pre-process options.", {
          duration: 4000,
        });
        return false;
      }
    }

    if (controlnetScales.length < 3) {
      toast.error(
        "Please select values for at least three control net scales."
      );
      return false;
    }

    if (/\s/.test(video_name)) {
      toast.error(
        "Video name cannot contain spaces. Please use an underscore or hyphen instead.",
        {
          duration: 4000,
        }
      );
      return false;
    }

    try {
      const canProceed = await checkSimultaneousGenerationRestyle(owner_id);
      if (!canProceed) {
        setShowModal(true);
        return false;
      }

      const response = await service.generate_restyle_video(
        owner_id,
        video_name,
        is_watermark,
        positivePrompt,
        subjectMatter,
        outputType,
        frameWidth,
        frameHeight,
        scalingFactor,
        ipadapterReferenceimage,
        controlnetsModels,
        controlnetsPreProcesses,
        controlnetScales,
        ipadapterScale
      );

      // Check if the response has an error status
      if (response.statusCode === 200) {
        toast.success("Video restyling in progress...", { duration: 7000 });

        setTimeout(() => {
          navigate("/app/library/restyle-videos");
        }, 4000);

        return true;
      }
    } catch (error: any) {
      if (error.status === "ERROR") {
        setShowModalRemain(true);
      } else {
        toast.error("Failed to start video restyling. Please try again.", {
          duration: 4000,
        });
      }
      return false;
    }
  }

  const width = isSmallScreen ? "700px" : "730px";

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    );
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(meta);
    };
  }, []);

  return (
    <>
      {isMobileDevice() ? (
        <div className="ps big-card rounded m-1 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-2">
            <h1 className="mt-3 fw-bold" style={{ fontSize: "24px" }}>
              {TITLE}
            </h1>
            <p style={{ fontSize: "14px" }}>{HEADER}</p>

            <div className="rounded prompt col-11 mt-3">
              <label>Describe what you want to see:</label>
              <input
                placeholder="Punk rock band playing (optional)"
                value={subjectMatter}
                onChange={handleSubjectMatterChange}
                style={{
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: "10px",
                  width: "340px",
                  height: "100%",
                }}
              />
            </div>

            <div className="rounded prompt col-11 mt-5">
              <label style={{ textAlign: "left", display: "block" }}>
                In the style of:
              </label>
              <input
                placeholder="Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K"
                value={inputValue}
                onChange={handlePositivePromptChange}
                style={{
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: "10px",
                  width: "340px",
                  height: "100%",
                }}
              />
            </div>

            <div className="d-flex flex-grow-1 align-items-stretch flex-column">
              <div className="d-flex flex-column p-2 overflow-hidden flex-grow-1">
                <div className="rounded mt-3 mb-3">
                  <select
                    onChange={handleOutputTypeChange}
                    className="form-select"
                  >
                    <option value="entire">Full Restyle (Default)</option>
                    <option value="foreground">Affect foreground only</option>
                    <option value="background">Affect background only</option>
                  </select>

                  <Select
                    isMulti
                    options={controlnets_models}
                    value={controlnetsModels}
                    onChange={handleModelChange}
                    styles={customStyles}
                    classNamePrefix="select"
                    formatOptionLabel={(option) => (
                      <div
                        onMouseEnter={(e) =>
                          setTooltip({
                            description: option.description,
                            left: e.clientX,
                            top: e.clientY,
                          })
                        }
                        onMouseLeave={() => setTooltip(null)}
                      >
                        {option.label}
                      </div>
                    )}
                  />
                  {tooltip && (
                    <div
                      style={{
                        position: "absolute",
                        left: tooltip.left,
                        top: tooltip.top,
                        backgroundColor: "white",
                        color: "black",
                        padding: "5px",
                        borderRadius: "5px",
                        zIndex: 10,
                        pointerEvents: "none",
                      }}
                    >
                      {tooltip.description}
                    </div>
                  )}
                  <Select
                    isMulti
                    options={controlnets_preprocesses}
                    value={controlnetsPreProcesses}
                    onChange={handleModelProcessChange}
                    styles={customStyles}
                    classNamePrefix="select"
                  />
                </div>

                <div
                  style={{ width: "100%", height: "170px", overflow: "hidden" }}
                >
                  <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    volume={0.8}
                    controls={true}
                  />
                </div>
              </div>

              <div className="d-flex flex-column p-2">
                <div className="mb-3">
                  <Tabs
                    setIsUpload={setIsUpload}
                    isUpload={isUpload}
                    setIpadapterImages={setIpadapterImages}
                    isIpadapterImages={isIpadapterImages}
                  />
                </div>
                {isUpload && (
                  <UploadBtnMobile
                    setIsUpload={setIsUpload}
                    setIsLoadingProgress={setIsLoadingProgress}
                  />
                )}

                {!isUpload && (
                  <ChooseFromYourLibrary
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                    setIpadapterScale={setIpadapterScale}
                    ipadapterScale={ipadapterScale}
                  />
                )}

                {isIpadapterImages && (
                  <ChooseIpadapterImage
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                    setIpadapterScale={setIpadapterScale}
                    ipadapterScale={ipadapterScale}
                  />
                )}

                <div className="d-flex align-items-start mt-3 mb-2">
                  <div className="col-6">
                    <NextPrev hideNext />
                  </div>
                  <button
                    className="btn btn-success d-flex align-items-center"
                    onClick={async () => {
                      const controlNetValues = controlnetsModels.map(
                        (model) => model.value
                      );

                      const controlProcessValues = controlnetsPreProcesses.map(
                        (model) => model.value
                      );

                      const success = await restyleGenerate(
                        ownerId,
                        videoName,
                        isWatermark,
                        positivePrompt,
                        subjectMatter,
                        outputType,
                        frameWidth,
                        frameHeight,
                        scalingFactor,
                        ipadapterReferenceimage,
                        controlNetValues,
                        controlProcessValues
                      );
                    }}
                  >
                    <i className="bi bi-magic me-2"></i>
                    Restyle
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ModalPurchase show={showModal} onHide={() => setShowModal(false)} />
          <ModalRemainCredits
            show={showModalRemain}
            onHide={() => setShowModalRemain(false)}
          />
        </div>
      ) : (
        <div className="ps big-card rounded m-3 d-flex flex-grow-1 align-items-center justify-content-center">
          <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center border-end h-100 p-3">
            {isPlayerVisible && <h1 className="mt-3 fw-bold">{TITLE}</h1>}
            {isPlayerVisible && <p>{HEADER}</p>}
            <strong>Describe what you want to see:</strong>

            <div
              className={`rounded prompt ${isSmallScreen ? "col-9" : "col-6"}`}
            >
              <input
                placeholder="Punk rock band playing (optional)"
                value={subjectMatter}
                onChange={handleSubjectMatterChange}
                style={{
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: "13px",
                  width: "630px",
                  height: "23px",
                }}
              />
            </div>

            <strong>In the style of:</strong>
            <div
              className={`rounded prompt ${isSmallScreen ? "col-9" : "col-6"}`}
            >
              <input
                placeholder="Synthwave, vibrant colors, neon lights, grid patterns, nostalgic, in the style of Retrowave, 8K"
                value={inputValue}
                onChange={handlePositivePromptChange}
                style={{
                  border: "none",
                  backgroundColor: "#0000",
                  fontSize: "13px",
                  width: "630px",
                  height: "23px",
                }}
              />
            </div>

            <div className="d-flex flex-grow-1 align-items-stretch col-9">
              <div
                className={`d-flex ${
                  isSmallScreen
                    ? "flex-column overflow-hidden flex-grow-1"
                    : "flex-column p-3 overflow-hidden flex-grow-1"
                }`}
              >
                <div className={`rounded ${isSmallScreen ? "" : "mt-3 mb-3"}`}>
                  <label>Effect Apply</label>
                  <select
                    onChange={handleOutputTypeChange}
                    className={`form-select ${isSmallScreen ? "mb-1" : "mb-3"}`}
                  >
                    <option value="entire">Full Restyle (Default)</option>
                    <option value="foreground">Affect foreground only</option>
                    <option value="background">Affect background only</option>
                  </select>
                  <label>Control Net Models</label>
                  <Select
                    isMulti
                    options={controlnets_models}
                    className={`${isSmallScreen ? "mb-1" : "mb-2"}`}
                    value={controlnetsModels}
                    onChange={handleModelChange}
                    styles={customStyles}
                    classNamePrefix="select"
                    formatOptionLabel={(option) => (
                      <div
                        onMouseEnter={(e) =>
                          setTooltip({
                            description: option.description,
                            left: e.clientX,
                            top: e.clientY,
                          })
                        }
                        onMouseLeave={() => setTooltip(null)}
                      >
                        {option.label}
                      </div>
                    )}
                  />
                  {tooltip && (
                    <div
                      style={{
                        position: "absolute",
                        left: tooltip.left,
                        top: tooltip.top,
                        backgroundColor: "white",
                        color: "black",
                        padding: "5px",
                        borderRadius: "5px",
                        zIndex: 10,
                        pointerEvents: "none",
                      }}
                    >
                      {tooltip.description}
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* Render Scale Inputs for controlnetsModels */}
                    {controlnetsModels.map((model, index) => (
                      <div
                        key={model.value}
                        style={{
                          marginRight: isSmallScreen ? "20px" : undefined,
                        }}
                      >
                        <label>{model.label}</label>
                        <input
                          className={`${isSmallScreen ? "mb-1" : "mb-2"}`}
                          type="number"
                          value={controlnetScales[index] || 0.5} // Default value
                          min={0.0}
                          max={1.0}
                          step={0.1}
                          onChange={(e) =>
                            handleScaleChange(index, parseFloat(e.target.value))
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <label>Control Net Preprocesses</label>
                  <Select
                    isMulti
                    options={controlnets_preprocesses}
                    value={controlnetsPreProcesses}
                    onChange={handleModelProcessChange}
                    styles={customStyles}
                    classNamePrefix="select"
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* Render Scale Inputs for controlnetsPreProcesses */}
                    {controlnetsPreProcesses.map((model, index) => (
                      <div key={model.value} style={{ marginRight: "20px" }}>
                        <label>{model.label}</label>
                        <input
                          className={`${isSmallScreen ? "mb-1" : "mb-2"}`}
                          type="number"
                          value={controlnetScales[index] || 0.5}
                          min={0.0}
                          max={1.0}
                          step={0.1}
                          onChange={(e) =>
                            handleScaleChange(index, parseFloat(e.target.value))
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {isPlayerVisible && (
                  <div
                    style={{
                      width: "350px",
                      height: isSmallScreen ? "150px" : "450px",
                      overflow: "hidden",
                    }}
                  >
                    <ReactPlayer
                      url={url}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      volume={0.8}
                      controls={true}
                    />
                  </div>
                )}
              </div>

              <div
                className="d-flex flex-column p-3"
                style={{ marginLeft: isSmallScreen ? "30px" : undefined }}
              >
                <div className={`${isSmallScreen ? "mb-3" : ""} `}>
                  <Tabs
                    setIsUpload={setIsUpload}
                    isUpload={isUpload}
                    setIpadapterImages={setIpadapterImages}
                    isIpadapterImages={isIpadapterImages}
                  />
                </div>
                {isUpload && (
                  <UploadBtn
                    setIsUpload={setIsUpload}
                    setIsLoadingProgress={setIsLoadingProgress}
                  />
                )}

                {!isUpload && !isIpadapterImages && (
                  <ChooseFromYourLibrary
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                    setIpadapterScale={setIpadapterScale}
                    ipadapterScale={ipadapterScale}
                  />
                )}
                {isIpadapterImages && (
                  <ChooseIpadapterImage
                    setNoImages={setNoImages}
                    setIpadapterReferenceimage={setIpadapterReferenceimage}
                    setIpadapterScale={setIpadapterScale}
                    ipadapterScale={ipadapterScale}
                  />
                )}

                <div className="d-flex align-items-start mt-auto mb-2">
                  <div className="col-9">
                    <NextPrev hideNext />
                  </div>
                  <button
                    className="btn btn-success d-flex align-items-center"
                    onClick={async () => {
                      const controlNetValues = controlnetsModels.map(
                        (model) => model.value
                      );

                      const controlProcessValues = controlnetsPreProcesses.map(
                        (model) => model.value
                      );

                      const success = await restyleGenerate(
                        ownerId,
                        videoName,
                        isWatermark,
                        positivePrompt,
                        subjectMatter,
                        outputType,
                        frameWidth,
                        frameHeight,
                        scalingFactor,
                        ipadapterReferenceimage,
                        controlNetValues,
                        controlProcessValues,
                        controlnetScales,
                        ipadapterScale
                      );
                    }}
                  >
                    <i className="bi bi-magic me-2"></i>
                    Restyle
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ModalPurchase show={showModal} onHide={() => setShowModal(false)} />
          <ModalRemainCredits
            show={showModalRemain}
            onHide={() => setShowModalRemain(false)}
          />
        </div>
      )}
    </>
  );
}

function Tabs(props: any) {
  const { isUpload, setIsUpload, isIpadapterImages, setIpadapterImages } =
    props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isSmallScreen = windowWidth < 1600;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="d-flex flex-column overflow-auto gap-3">
      <div
        className={`d-flex justify-content-center ${
          isSmallScreen ? "gap-3" : "gap-5"
        }`}
      >
        <div
          onClick={() => {
            setIsUpload(true);
            setIpadapterImages(false);
          }}
          style={{
            borderBottom: isUpload ? "2px #FF30C4 solid" : "",
            textAlign: "center",
          }}
          className={` ${isSmallScreen ? "flex-grow-1" : ""}`}
        >
          {UPLOAD}
        </div>
        <div
          onClick={() => {
            setIsUpload(false);
            setIpadapterImages(false);
          }}
          style={{
            borderBottom:
              !isUpload && !isIpadapterImages ? "2px #FF30C4 solid" : "none",
            textAlign: "center",
          }}
          className={` ${isSmallScreen ? "flex-grow-1" : ""}`}
        >
          {CFY_LYBRARY}
        </div>
        <div
          onClick={() => {
            setIsUpload(false);
            setIpadapterImages(true);
          }}
          style={{
            borderBottom: isIpadapterImages ? "2px #FF30C4 solid" : "none",
            textAlign: "center",
          }}
          className={` ${isSmallScreen ? "flex-grow-1" : ""}`}
        >
          {IPADAPTER_REF_IMAGE}
        </div>
      </div>
    </div>
  );
}

function UploadBtn(props: any) {
  const { setIsUpload, setIsLoadingProgress } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
  const client = new APIClient();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoadingProgress(true);

    const droppedFiles = Array.from(e.dataTransfer.files);

    client.authenticate(undefined, undefined, API_KEY);

    if (droppedFiles.length > 0) {
      for (const file of droppedFiles) {
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed: ${file.name}`, { duration: 5000 });
          continue;
        }

        const isValid = await validateImageDimensions(file);
        if (!isValid) {
          setIsLoadingProgress(false);
          toast.error(`Reference image should be at least 768x768px`, {
            duration: 5000,
          });
          continue;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed: ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingProgress(true);
    const files = e.target.files;

    client.authenticate(undefined, undefined, API_KEY);

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed: ${file.name}`, { duration: 5000 });
          continue;
        }

        const isValid = await validateImageDimensions(file);
        if (!isValid) {
          setIsLoadingProgress(false);
          toast.error(`Reference image should be at least 768x768px`, {
            duration: 5000,
          });
          continue;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed: ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const validateImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      console.log(img);
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string") {
          img.src = reader.result;
        }
      };

      img.onload = () => {
        const isValid = img.width >= 768 && img.height >= 768;
        resolve(isValid);
      };

      reader.readAsDataURL(file);
    });
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "image/png, image/jpeg",
  };
  return (
    <div
      className="upload-btn"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...{ onClick }}
    >
      <input type="file" multiple {...inp} onChange={handleChange} />
      <i className="bi bi-upload" />
      <div
        style={{ fontSize: "small" }}
        className="align-items-center justify-content-center p-4"
      >
        <p className="text-center">{SUBTITLE_IMAGE}</p>
      </div>
    </div>
  );
}

function UploadBtnMobile(props: any) {
  const { setIsUpload, setIsLoadingProgress } = props;
  const API_KEY = process.env.REACT_APP_MUZE_API_KEY;
  const client = new APIClient();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const inputFile = useRef<HTMLInputElement | null>(null);
  const onClick = async () => inputFile.current?.click();

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsLoadingProgress(true);

    const droppedFiles = Array.from(e.dataTransfer.files);

    client.authenticate(undefined, undefined, API_KEY);

    if (droppedFiles.length > 0) {
      for (const file of droppedFiles) {
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed: ${file.name}`, { duration: 5000 });
          continue;
        }

        const isValid = await validateImageDimensions(file);
        if (!isValid) {
          setIsLoadingProgress(false);
          toast.error(`Reference image should be at least 768x768px`, {
            duration: 5000,
          });
          continue;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed: ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingProgress(true);
    const files = e.target.files;

    client.authenticate(undefined, undefined, API_KEY);

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!validFileType(file, commonImageTypes)) {
          setIsLoadingProgress(false);
          toast.error(`Format not allowed: ${file.name}`, { duration: 5000 });
          continue;
        }

        const isValid = await validateImageDimensions(file);
        if (!isValid) {
          setIsLoadingProgress(false);
          toast.error(`Reference image should be at least 768x768px`, {
            duration: 5000,
          });
          continue;
        }

        client.upload_image_asset_restyle(ownerId, file, (response: any) => {
          if (response?.statusText === "OK") {
            toast.success(`Image upload success! ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
            setIsUpload(false);
          } else {
            toast.error(`Image upload failed: ${file.name}`, {
              duration: 5000,
            });
            setIsLoadingProgress(false);
          }
        });
      }
    }
  };

  const validateImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      console.log(img);
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === "string") {
          img.src = reader.result;
        }
      };

      img.onload = () => {
        const isValid = img.width >= 768 && img.height >= 768;
        resolve(isValid);
      };

      reader.readAsDataURL(file);
    });
  };

  const inp = {
    id: "file",
    ref: inputFile,
    style: { display: "none" },
    accept: "image/png, image/jpeg",
  };

  return (
    <div
      className="upload-btn-mobile"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...{ onClick }}
    >
      <input type="file" multiple {...inp} onChange={handleChange} />
      <i className="bi bi-upload" />
      <div
        style={{ fontSize: "small" }}
        className="align-items-center justify-content-center p-2"
      >
        <p className="text-center">{SUBTITLE_IMAGE}</p>
      </div>
    </div>
  );
}

function ChooseFromYourLibrary({
  setNoImages,
  setIpadapterReferenceimage,
  setIpadapterScale,
  ipadapterScale,
}: {
  setNoImages: any;
  setIpadapterReferenceimage: any;
  setIpadapterScale: any;
  ipadapterScale: any;
}) {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const containerClassName = isMobileDevice()
    ? "choose-from-library-new-mobile overflow-hidden"
    : "choose-from-library-restyle overflow-hidden";

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/initial-image/assets/restyle?owner_id=${encodeURIComponent(
          ownerId
        )}&page=${page}&per_page=${perPage}`
      )
      .then((res: any) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `${REACT_APP_IMAGES_CDN_URL}restyle/${encodeURIComponent(
              ownerId
            )}/${encodeURIComponent(el.Key)}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className={containerClassName}>
      {/* Scale Input */}
      <div className="p-3">
        <label htmlFor="ipadapterScale">Set IP Adapter Scale</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            id="ipadapterScale"
            type="range"
            min="0.0"
            max="1.0"
            step="0.01"
            value={ipadapterScale}
            onChange={(e) => setIpadapterScale(parseFloat(e.target.value))}
            className="range-slider" // Apply the custom class
          />
          <span style={{ marginLeft: "10px", color: "#ff30c4" }}>
            <strong>{ipadapterScale.toFixed(2)}</strong>{" "}
            {/* Display the current value */}
          </span>
        </div>
        <strong>
          0 means barely considers the IPAdapter reference image, while 1 means
          barely considers the prompt.
        </strong>
      </div>
      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard
                  key={image.name}
                  {...image}
                  setIpadapterReferenceimage={setIpadapterReferenceimage}
                />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <Paginator {...paginator} />
        </>
      )}
      {isLoading && <Loading />}
      {isError && <Error />}
    </div>
  );
}

function ChooseIpadapterImage({
  setNoImages,
  setIpadapterReferenceimage,
  setIpadapterScale,
  ipadapterScale,
}: {
  setNoImages: any;
  setIpadapterReferenceimage: any;
  setIpadapterScale: any;
  ipadapterScale: any;
}) {
  const { refetch, isLoading, isError } = useImageLibrary();
  const { currentUser } = useAuth();
  const paginator = usePaginator();
  const { actual, setTotal } = paginator;
  const page = actual;
  const perPage = 10;
  const [formatedImages, setFormatedImages] = useState<any[]>([]);

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  const containerClassName = isMobileDevice()
    ? "choose-from-library-new-mobile overflow-hidden"
    : "choose-from-library-restyle overflow-hidden";

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setNoImages(formatedImages.length === 0);
  }, [formatedImages.length, setNoImages]);

  const getImages = () => {
    axios
      .get(
        `${REACT_APP_MUZE_API_HOST}/ipadapter-images/assets?page=${page}&per_page=${perPage}`
      )
      .then((res: any) => {
        if (res.data.results.length === 0 || !Array.isArray(res.data.results)) {
          setTotal(0);
          setFormatedImages([]);
          return;
        }
        const formatedImages = res.data.results.map(
          (el: any, index: number, array: any[]) => ({
            src: `https://d3aa5rqohsahcz.cloudfront.net/${encodeURIComponent(
              el.Key
            )}`,
            name: el.Key,
            date: new Date(el.LastModified).toLocaleDateString(),
            isLast: index === array.length - 1,
          })
        );
        setTotal(res.data.total);
        setFormatedImages(formatedImages);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useCustomEventListener("onImageUpload", () => {
    getImages();
  });

  useEffect(() => {
    getImages();
  }, [actual]);

  return (
    <div className={containerClassName}>
      {/* Scale Input */}
      <div className="p-3">
        <label htmlFor="ipadapterScale">Set IP Adapter Scale</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            id="ipadapterScale"
            type="range"
            min="0.0"
            max="1.0"
            step="0.01"
            value={ipadapterScale}
            onChange={(e) => setIpadapterScale(parseFloat(e.target.value))}
            className="range-slider" // Apply the custom class
          />
          <span style={{ marginLeft: "10px", color: "#ff30c4" }}>
            <strong>{ipadapterScale.toFixed(2)}</strong>{" "}
            {/* Display the current value */}
          </span>
        </div>
        <strong>
          0 means barely considers the IPAdapter reference image, while 1 means
          barely considers the prompt.
        </strong>
      </div>

      {!isLoading && !isError && (
        <>
          <div className="d-flex flex-wrap justify-content-start overflow-auto p-3 gap-3">
            {formatedImages.length > 0 ? (
              formatedImages.map((image: any) => (
                <ImageCard
                  key={image.name}
                  {...image}
                  setIpadapterReferenceimage={setIpadapterReferenceimage}
                />
              ))
            ) : (
              <div>{formatedImages.length === 0 ? MESSAGE : null}</div>
            )}
          </div>
          <Paginator {...paginator} />
        </>
      )}
      {isLoading && <Loading />}
      {isError && <Error />}
    </div>
  );
}

function useImageLibrary() {
  const { currentUser } = useAuth();
  const ownerId = currentUser?.email || "";
  const paginator = usePaginator();
  const { actual, setTotal, setItemsPerPage, itemsPerPage } = paginator;
  const page = actual;
  const perPage = itemsPerPage;
  const { data, isLoading, refetch, isError } = useGetImageAssetsQuery({
    ownerId,
    page,
    perPage,
  });
  const { setUserInitialImageKey, initialImageKey } = useStartingFrame();

  const images = Array.isArray(data?.results)
    ? data.results.map((i: any, k: number, l: any[]) => ({
        img: `${imageBucketBaseURL}/${ownerId}/${i.Key}`,
        id: i.Key,
        isLast: k === l.length - 1,
      }))
    : [];

  return {
    refetch,
    images,
    isLoading,
    isError,
    setUserInitialImageKey,
    initialImageKey,
  };
}

function ImageCard(props: any) {
  const { src, name, setIpadapterReferenceimage } = props;
  const [isSelected, setIsSelected] = useState(false);

  const downloadImage = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = () => {
    setIsSelected(!isSelected); // Toggle selection
    setIpadapterReferenceimage(!isSelected ? src : "ip_image_9.png");
  };

  const className = `d-flex flex-column align-items-center justify-content-center image-card ${
    isSelected ? "image-selected" : ""
  }`;

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return /mobile/.test(userAgent);
  };

  return (
    <>
      {isMobileDevice() ? (
        <div
          className={`${className} position-relative`}
          onClick={handleClick}
          style={{
            width: "90px", // Ajuste o tamanho desejado
            height: "90px", // Ajuste o tamanho desejado
            overflow: "hidden", // Esconde partes da imagem que ultrapassam o contêiner

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra sutil ao card
          }}
        >
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%", // Corrige o borderRadius para círculo
              padding: "5px",
              cursor: "pointer",
              zIndex: 10, // Garante que o botão esteja acima da imagem
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "16px" }} // Ajusta o tamanho do ícone
            ></i>
          </button>
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Garante que a imagem cubra todo o card
            }}
          />
        </div>
      ) : (
        <div
          className={`${className} position-relative`}
          onClick={handleClick}
          style={{
            width: "100px", // Ajuste o tamanho desejado
            height: "100px", // Ajuste o tamanho desejado
            overflow: "hidden", // Esconde partes da imagem que ultrapassam o contêiner

            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adiciona uma sombra sutil ao card
          }}
        >
          <button
            className="btn btn-download position-absolute"
            onClick={downloadImage}
            style={{
              top: "10px",
              right: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              border: "none",
              borderRadius: "50%", // Corrige o borderRadius para círculo
              padding: "5px",
              cursor: "pointer",
              zIndex: 10, // Garante que o botão esteja acima da imagem
            }}
          >
            <i
              className="bi bi-cloud-arrow-down-fill"
              style={{ fontSize: "16px" }} // Ajusta o tamanho do ícone
            ></i>
          </button>
          <img
            className="d-flex flex-column align-items-center justify-content-center"
            src={`${src}`}
            alt="initial frame"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Garante que a imagem cubra todo o card
            }}
          />
        </div>
      )}
    </>
  );
}

function Loading() {
  return (
    <div className="d-flex flex-grow-1 align-items-center justify-content-center">
      <h3>Loading ...</h3>
    </div>
  );
}

function Error() {
  return (
    <div className="d-flex flex-grow-1 flex-column gap-3 align-items-center justify-content-center">
      <h5>Something went wront ...</h5>
      <p>Please try later.</p>
    </div>
  );
}
